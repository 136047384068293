export const acc_components = {
  title: 'ACC Components',
  saveLabel: 'Save',
  updateLabel: 'Update',
  questions: [
    {
      title: '4.1 Gearbox',
      forms: [
        {
          type: 'text',
          description: '1. Please specify the below parameters.',
          fields: [
            { key: 'gearbox_manufacturer', label: 'Manufacturer' },
            { key: 'gear_ratio', label: 'Gear Ratio' },
            { key: 'teeth_per_gear', label: 'Nr. of Teeth per gear' },
            { key: 'gearbox_bearing_type', label: 'Bearing Type' },
          ],
        },
        {
          type: 'upload',
          name: 'gearbox_datasheet',
          description:
            '2. Please attach the Datasheet and Drawing for the Gearbox.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '4.2 Fan Motor',
      forms: [
        {
          type: 'radio',
          description: '1. Please specify the type of Fan Motor installed.',
          name: 'motor_type',
          fields: [
            {
              key: 'singleSpeed',
              label: 'Single Speed',
            },
            {
              key: 'twoSpeed',
              label: '2-Speed',
            },
            {
              key: 'variableSpeed',
              label: 'Variable Speed',
            },
          ],
        },
        {
          type: 'text',
          description: '2. Please specify the below parameters.',
          fields: [
            { key: 'motor_speed', label: 'Speed (RPM)' },
            { key: 'motor_manufacturer', label: 'Manufacturer' },
            {
              key: 'motor_power_consumption',
              label: 'Current (%, Amps, kW)',
            },
            { key: 'motor_bearing_type', label: 'Bearing Type' },
          ],
        },
        {
          type: 'upload',
          name: 'motor_datasheet',
          description:
            '3. Please attach the Datasheet and Drawing for the Fan Motor.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '4.3 Fan',
      forms: [
        {
          type: 'text',
          description: '1. Please specify the below parameters.',
          fields: [
            { key: 'fan_manufacturer', label: 'Manufacturer' },
            { key: 'fan_type', label: 'Type' },
            { key: 'fan_diameter', label: 'Fan Diameter (ft)' },
            { key: 'nr_of_blades', label: 'Nr. of Blades' },
            { key: 'coupling_type', label: 'Coupling Type' },
          ],
        },
        {
          type: 'radio',
          description: '2. Please specify the fixation type',
          name: 'fan_fixation_type',
          fields: [
            {
              key: 'fixed',
              label: 'Directly Fixed',
            },
            {
              key: 'coupling',
              label: 'Through Coupling',
            },
          ],
        },
        {
          type: 'upload',
          name: 'fan_datasheet',
          description:
            '3. Please attach the Datasheet and Drawing for the Fan.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '4.4 Vacuum Systems',
      forms: [
        {
          type: 'text',
          description: '1. Please specify the below parameters.',
          fields: [
            { key: 'vacuum_manufacturer', label: 'Manufacturer' },
            { key: 'vacuum_configuration', label: 'General Configuration' },
          ],
        },
        {
          type: 'checkbox',
          description:
            '2. Please select the available parameters of the Steam Ejector.',
          name: 'steam_ejector_parameters',
          fields: [
            {
              key: 'valve_status',
              label: 'Valve Status',
            },
            {
              key: 'motive_steam_pressure',
              label: 'Motive Steam Pressure',
            },
            {
              key: 'motive_steam_temperature',
              label: 'Motive Steam Temperature',
            },
            {
              key: 'suction_pressure',
              label: 'Suction Pressure',
            },
            {
              key: 'suction_temperature',
              label: 'Suction Temperature',
            },
          ],
        },
        {
          type: 'checkbox',
          description:
            '3. Please select the available parameters of Vacuum Pumps.',
          name: 'vacuum_pump_parameters',
          fields: [
            {
              key: 'pump_status',
              label: 'Pump Status',
            },
            {
              key: 'pump_valve_status',
              label: 'Valve Status',
            },
            {
              key: 'pump_suction_pressure',
              label: 'Suction Pressure',
            },
            {
              key: 'pump_suction_temperature',
              label: 'Suction Temperature',
            },
            {
              key: 'pump_cooling_water_temperature',
              label: 'Cooling Water Temperature',
            },
          ],
        },
        {
          type: 'upload',
          name: 'vacuum_system_datasheet',
          description:
            '4. Please attach the Datasheet and Drawing for the Vacuum Systems.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '4.5 Drain Pumps',
      forms: [
        {
          type: 'text',
          description: '1. Please specify the below parameters.',
          fields: [{ key: 'drain_pump_manufacturer', label: 'Manufacturer' }],
        },
        {
          type: 'checkbox',
          description:
            '2. Please select the available parameters of the Drain Pumps.',
          name: 'drain_pump_parameters',
          fields: [
            {
              key: 'drain_pump_status',
              label: 'Status',
            },
            {
              key: 'drain_pump_volume_flow',
              label: 'Pump Volume Flow',
            },
          ],
        },
        {
          type: 'upload',
          name: 'drain_pump_datasheet',
          description: '3. Please attach the Datasheet for the Drain Pump.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '4.6 Drain Pot',
      forms: [
        {
          type: 'checkbox',
          description:
            '1. Please select the available parameters of the Drain Pot.',
          name: 'drain_pot_parameters',
          fields: [
            {
              key: 'drain_pot_level',
              label: 'Level',
            },
            {
              key: 'drain_pot_temperature',
              label: 'Temperature',
            },
          ],
        },
        {
          type: 'text',
          description: '2. Please specify the below parameters.',
          fields: [
            { key: 'drain_pot_volume', label: 'Volume (Datasheet value)' },
          ],
        },
        {
          type: 'upload',
          name: 'drain_pot_datasheet',
          description:
            '3. Please attach the Datasheet and Drawing for the Drain Pot.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '4.7 Condensate Extraction Pumps',
      forms: [
        {
          type: 'text',
          description: '1. Please specify the below parameters.',
          fields: [
            {
              key: 'condensate_extraction_pump_manufacturer',
              label: 'Manufacturer',
            },
          ],
        },
        {
          type: 'checkbox',
          description:
            '2. Please select the available parameters of the Condensate Extraction Pump.',
          name: 'condensate_extraction_pump_parameters',
          fields: [
            {
              key: 'condensate_extraction_pump_status',
              label: 'Status',
            },
            {
              key: 'condensate_extraction_pump_volume_flow',
              label: 'Pump Volume Flow',
            },
          ],
        },
        {
          type: 'upload',
          name: 'drain_pump_datasheet',
          description:
            '3. Please attach the Datasheet for the Condensate Extraction Pump.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '4.8 Condensate Tank',
      forms: [
        {
          type: 'checkbox',
          description:
            '1. Please select the available parameters of the Condensate Tank.',
          name: 'condensate_tank_parameters',
          fields: [
            {
              key: 'condensate_tank_level',
              label: 'Level',
            },
            {
              key: 'condensate_tank_temperature',
              label: 'Temperature',
            },
          ],
        },
        {
          type: 'text',
          description: '2. Please specify the below parameters.',
          fields: [
            {
              key: 'condensate_tank_volume',
              label: 'Volume (Datasheet value)',
            },
          ],
        },
        {
          type: 'upload',
          name: 'condensate_tank_datasheet',
          description:
            '3. Please attach the Datasheet and Drawing for the Condensate Tank.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '4.9 Cleaning Pump',
      forms: [
        {
          type: 'text',
          description: '1. Please specify the below parameters.',
          fields: [
            {
              key: 'cleaning_pump_manufacturer',
              label: 'Manufacturer',
            },
          ],
        },
        {
          type: 'checkbox',
          description:
            '2. Please select the available parameters of the Cleaning Pump.',
          name: 'condensate_extraction_pump_parameters',
          fields: [
            {
              key: 'cleaning_pump_status',
              label: 'Status',
            },
            {
              key: 'cleaning_pump_volume_flow',
              label: 'Active Volume Flow',
            },
          ],
        },
        {
          type: 'textarea',
          name: 'cleaning_pump_purpose',
          description:
            '3. Please list the additional purposes of the Cleaning Pump, if any.',
        },
        {
          type: 'upload',
          name: 'cleaning_pump_datasheet',
          description: '4. Please attach the Datasheet for the Cleaning Pump.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '4.10 Fogging Systems',
      forms: [
        {
          type: 'checkbox',
          description:
            '2. Please select the available parameters of the Fogging System.',
          name: 'fogging_system_parameters',
          fields: [
            {
              key: 'fogging_system_status',
              label: 'Status',
            },
            {
              key: 'fogging_system_volume_flow',
              label: 'Active Volume Flow',
            },
          ],
        },
        {
          type: 'textarea',
          name: 'fogging_system_purpose',
          description:
            '3. Please list the additional purposes of the Fogging System, if any.',
        },
      ],
    },
    {
      title: '4.11 Bypass Valves',
      forms: [
        {
          type: 'radio',
          description:
            '1. Please specify if the status of the Bypass Valve is available.',
          name: 'bypass_valve_status',
          fields: [
            {
              key: 'bypass_valve_yes',
              label: 'Yes',
            },
            {
              key: 'bypass_valve_no',
              label: 'No',
            },
          ],
        },
        {
          type: 'radio',
          description: '2. Please specify the type of measurement.',
          name: 'bypass_valve_measurement_type',
          fields: [
            {
              key: 'openClose',
              label: 'Open/Close',
            },
            {
              key: 'position',
              label: 'Position',
            },
          ],
        },
      ],
    },
    {
      title: '4.12 Wind Mitigation Device',
      forms: [
        {
          type: 'radio',
          description:
            '1. Please specify if a Wind Mitigation device or system has been installed on site.',
          name: 'wind_mitigation_installed',
          fields: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
        },
        {
          type: 'radio',
          description: '2. Please specify the type of device installed.',
          name: 'wind_mitigation_device_type',
          other: 'wind_mitigation_device_notes',
          fields: [
            {
              key: 'fixed',
              label: 'Fixed',
            },
            {
              key: 'retractable',
              label: 'Retractable',
            },
            {
              key: 'not_applicable',
              label: 'Not applicable',
            },
          ],
        },
        {
          type: 'upload',
          name: 'wind_mitigation_device_drawing',
          description:
            '2. Please attach the Drawing for the Wind Mitigation Device.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '4.13 Anemometer / Wind Measurement Device',
      forms: [
        {
          type: 'text',
          description: '1. Please specify the below parameters.',
          fields: [
            { key: 'anemometer_elevation', label: 'Elevation' },
            { key: 'anemometer_location', label: 'Location' },
            { key: 'location_plant_layout', label: 'Plant Layout Indicators' },
          ],
        },
        {
          type: 'textarea',
          name: 'wind_direction_references',
          description:
            '2. Please specify the reference for wind direction. Also mention if 0° is the True North.',
        },
      ],
    },
  ],
};
