const envMap = {
  dev: 'http://localhost:8000',
  acc: 'https://tst-api.rpm.acc-360.com',
  prod: 'https://api.rpm.acc-360.com',
};

const environment = window.location.origin.match(/localhost|127.0.0.1/gi)
  ? 'dev'
  : 'acc';

localStorage.setItem('acc360.audience', envMap[environment]);
