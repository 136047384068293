import { takeLatest, put } from 'redux-saga/effects';

import { Fetch } from '../fetch';

import {
  GET_COMPONENTS,
  GET_COMPONENTS_FAILURE,
  GET_COMPONENTS_SUCCESS,
} from './questionnaire.types';

function* getComponents() {
  try {
    const response = yield Fetch('/onboarding/projects/components');
    if (response.ok) {
      const components = yield response.json();
      localStorage.setItem('acc360.components', JSON.stringify(components));
      let progress = 0;
      components.forEach(
        (component) => (progress += component.completion_count)
      );
      progress = (progress / components.length) * 100;
      yield put({
        type: GET_COMPONENTS_SUCCESS,
        payload: { components, progress: Math.ceil(progress) },
      });
    } else {
      yield put({ type: GET_COMPONENTS_FAILURE });
    }
  } catch (err) {
    yield put({ type: GET_COMPONENTS_FAILURE });
  }
}

function* getComponentsSaga() {
  yield takeLatest(GET_COMPONENTS, getComponents);
}

export { getComponentsSaga };
