export const Fetch = (path, opts = {}) => {
  const audience = localStorage.getItem('acc360.audience');
  const options = { ...opts };
  const { sub, accessToken } = JSON.parse(localStorage.getItem('acc360.user'));
  options.headers = {
    Authorization: `Bearer ${accessToken}`,
    'x-spg-sub': sub,
  };
  if (['POST', 'PATCH'].includes(options.method)) {
    options.headers['Content-Type'] = 'application/json';
  }
  return fetch(`${audience}${path}`, options);
};
