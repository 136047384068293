import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Card, CardContent, Divider } from '@material-ui/core';
import { AttachmentOutlined } from '@material-ui/icons';

const ComponentCard = ({
  number,
  title,
  path,
  completion_count,
  attachment_count,
}) => {
  const history = useHistory();
  const onClick = () => {
    history.push(path);
  };
  const completion = Math.ceil(completion_count * 100);
  return (
    <Grid item sm={4}>
      <Card className='onboarding-form' onClick={onClick}>
        <CardContent>
          <h3>
            <span>{number}. </span>
            {title}
          </h3>
          <div className='component-icon'>
            <AttachmentOutlined />
            <p>{attachment_count}</p>
          </div>
          <Divider />
          <div className='progress-bar'>
            <div>
              <p>{completion} %</p>
              <div
                style={{
                  width: `${completion}%`,
                }}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ComponentCard;
