export const acc_general_data = {
  title: 'ACC General Data',
  saveLabel: 'Save',
  updateLabel: 'Update',
  questions: [
    {
      title: '3.1 Main Datasheet',
      forms: [
        {
          type: 'upload',
          name: 'main_datasheet',
          description: '1. Please attach the ACC Main Datasheet.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '3.2 Performance Curve',
      forms: [
        {
          type: 'upload',
          name: 'performance_curve',
          description: '1. Please attach the ACC Performance Curves.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '3.3 P&ID',
      forms: [
        {
          type: 'upload',
          name: 'p_n_id',
          description: '1. Please attach the P&ID of the entire steam cycle.',
          label: 'Upload',
        },
        {
          type: 'radio',
          description:
            '2. Can the tags from the DCS be directly mapped to the P&ID tags?',
          name: 'can_tags_map',
          fields: [
            { key: 'yes', label: 'Yes' },
            { key: 'no', label: 'No' },
          ],
        },
      ],
    },
    {
      title: '3.4 Power Plant Layout',
      forms: [
        {
          type: 'upload',
          name: 'power_plant_layout',
          description: '1. Please attach the power plant layout.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '3.5 Steam Turbine Curve',
      forms: [
        {
          type: 'upload',
          name: 'steam_turbine_curve',
          description:
            '1. Please attach set of curves at different steam flows i.e. steam turbine output as a function of back pressure at different steam flows.',
          label: 'Upload',
        },
        {
          type: 'upload',
          name: 'trip_curve',
          description:
            '2. Please attach the steam turbine trip and alarm curve. If not available, please provide the turbine trip and alarm levels (in mbar).',
          label: 'Upload',
        },
        {
          type: 'text',
          description:
            '3. Please specify the below parameters if Steam turbine trip and alarm curve are not present.',
          fields: [
            { key: 'turbine_trip', label: 'Turbine Trip (mbar)' },
            { key: 'alarm_levels', label: 'Alarm Levels (mbar)' },
          ],
        },
      ],
    },
    {
      title: '3.6 Heat and Mass Balance',
      forms: [
        {
          type: 'upload',
          name: 'turbine_isoentropic_efficiency',
          description:
            '1. Please upload the Turbine Isentropic Efficiency Diagram.',
          label: 'Upload',
        },
        {
          type: 'upload',
          name: 'heat_mass_diagram',
          description: '2. Please attach the Heat & Mass Balance Diagram.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '3.7 Fan Control Step',
      forms: [
        {
          type: 'upload',
          name: 'fan_control_step',
          description:
            '1. Please attach supporting documents for the Fan Control Step.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '3.8 DCS Screenshots',
      forms: [
        {
          type: 'upload',
          name: 'dcs_screenshots',
          description:
            '1. Please attach screenshots of the complete steam cycle to enable SPG DC to collate the required tags.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '3.9 Maintenance Activities',
      forms: [
        {
          type: 'upload',
          name: 'maintenance',
          description:
            '1. Please attach a list of ACC cleaning records and maintenance activities.',
          label: 'Upload',
        },
      ],
    },
  ],
};
