import { existing_sensors } from './existing-sensors.cms';
import { new_sensors } from './new-sensors.cms';
import { acc_general_data } from './acc-general-data.cms';
import { acc_components } from './acc-components.cms';
import { steam_parameters } from './steam-parameters.cms';
import { ambient_parameters } from './ambient-parameters.cms';
import { condensate_parameters } from './condensate-parameters.cms';
import { fan_parameters } from './fan-parameters.cms';
import { acc_history } from './acc-history.cms';
import { header } from './header.cms';

const cms = {
  existing_sensors,
  new_sensors,
  acc_general_data,
  acc_components,
  steam_parameters,
  ambient_parameters,
  condensate_parameters,
  fan_parameters,
  acc_history,
  header,
};

export default cms;
