import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
} from '@material-ui/core';

const AppDialog = ({ name, id, title, description, closeLabel, label }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button color='inherit' onClick={handleClickOpen}>
        {label}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={name}
        aria-describedby={id}
        className='onboarding-dialog'
      >
        <DialogTitle id={name}>{title}</DialogTitle>
        <DialogContent dividers>
          <div id={id}>
            {description.map((text, index) =>
              text.includes('@') ? (
                <Typography
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: `Email: <a href="mailto:${text}">${text}</a>`,
                  }}
                />
              ) : (
                <Typography key={index}>{text}</Typography>
              )
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {closeLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { AppDialog as Dialog };
