import { GET_COMPONENTS_SUCCESS } from './questionnaire.types';

const INITIAL_STATE = {
  progress: 0,
  components: JSON.parse(localStorage.getItem('acc360.components')) || [],
};

const questionnaire = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_COMPONENTS_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default questionnaire;
