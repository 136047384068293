export const GET_PROJECT = 'GET_PROJECTS';
export const GET_PROJECT_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE';
export const SAVE_PROJECT = 'SAVE_PROJECT';
export const SAVE_PROJECT_SUCCESS = 'SAVE_PROJECT_SUCCESS';
export const SAVE_PROJECT_FAILURE = 'SAVE_PROJECT_FAILURE';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const SUBMIT_PROJECT = 'SUBMIT_PROJECT';
export const SUBMIT_PROJECT_SUCCESS = 'SUBMIT_PROJECT_SUCCESS';
export const SUBMIT_PROJECT_FAILURE = 'SUBMIT_PROJECT_FAILURE';
