import * as Yup from 'yup';

let ExistingSensorsSchema = Yup.object().shape({
  historian_type: Yup.string().required('Please specify a Historian type'),
  historian_type_notes: Yup.string()
    .nullable()
    .when('historian_type', {
      is: 'others',
      then: Yup.string()
        .required('Please specify the Historian type')
        .min(2, 'Historian type cannot be less than 2 characters')
        .max(50, 'Historian type cannot be more than 50 characters'),
    }),
  server_name: Yup.string()
    .min(7, 'Server name cannot be less than 7 characters')
    .max(100, 'Server name cannot be more than 100 characters'),
  host_name: Yup.string()
    .min(7, 'Host name cannot be less than 7 characters')
    .max(100, 'Host name cannot be more than 100 characters'),
  user_name: Yup.string()
    .min(4, 'Username cannot be less than 4 characters')
    .max(30, 'Host name cannot be more than 30 characters'),
  password: Yup.string()
    .min(8, 'Password cannot be less than 8 characters')
    .max(24, 'Password cannot be more than 24 characters'),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Please enter the same password entered above'
    ),
  authentication_type: Yup.string().required(
    'Please specify a Authentication type'
  ),
  authentication_type_notes: Yup.string()
    .nullable()
    .when('authentication_type', {
      is: 'others',
      then: Yup.string()
        .required('Please specify the Authentication type')
        .min(2, 'Authentication type cannot be less than 2 characters')
        .max(255, 'Authentication type cannot be more than 20characters'),
    }),
  limitation_notes: Yup.string()
    .min(2, 'Limitations cannot be less than 2 characters')
    .max(255, 'Limitations cannot be more than 20characters'),
});
export default ExistingSensorsSchema;
