import React from 'react';

import {
  FormControl,
  RadioGroup,
  Radio,
  Grid,
  TextField,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core';

import { Tooltip } from '../tooltip.component';

const RadioInput = ({
  name,
  fields,
  other,
  handleChange,
  errors,
  touched,
  values,
}) => {
  return (
    <FormControl component='fieldset' error={!!(errors[name] && touched[name])}>
      <RadioGroup
        aria-label={name}
        name={name}
        onChange={handleChange}
        required
        value={values[name]}
      >
        {fields.map(({ key, label, popover, link }, index) => (
          <Grid item sm={3} key={index}>
            <FormControlLabel
              value={key}
              control={<Radio color='primary' />}
              label={label}
            />
            {popover && <Tooltip {...{ name: key, popover, link }} />}
          </Grid>
        ))}
        {other && (
          <Grid item>
            <FormControlLabel
              value='others'
              control={<Radio color='primary' />}
              label='Others'
            />
            <TextField
              name={other}
              value={values[other] || ''}
              error={!!(errors[other] && touched[other])}
              onChange={handleChange}
              helperText={
                errors[other] && touched[other] ? errors[other] : null
              }
            />
          </Grid>
        )}
      </RadioGroup>
      <FormHelperText>
        {errors[name] && touched[name] ? errors[name] : null}
      </FormHelperText>
    </FormControl>
  );
};

export default RadioInput;
