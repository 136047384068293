import React from 'react';

import { Grid, TextField } from '@material-ui/core';

const InputGroup = ({ fields, handleChange, errors, touched, values }) => {
  return (
    <div className='onboarding-input-group'>
      {fields.map(({ key, label }, index) => (
        <Grid item sm={3} key={index}>
          <TextField
            name={key}
            type={`${key.match(/password/gi) ? 'password' : 'text'}`}
            value={values[key]}
            label={label}
            error={!!(errors[key] && touched[key])}
            onChange={handleChange}
            helperText={errors[key] && touched[key] ? errors[key] : null}
          />
        </Grid>
      ))}
    </div>
  );
};

export default InputGroup;
