import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import { useSelector } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Header from './components/header';
import ProjectSetup from './pages/project-setup/project-setup.component';
import Questionnaire from './pages/questionnaire/questionnaire.component';
import Submission from './components/submission';
import { QuestionForm } from './pages/question-form/question-form';
import { useAuth0 } from '@auth0/auth0-react';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#e05215',
    },
  },
});

function App() {
  const { isSetupComplete } = useSelector((state) => state.projectSetup);
  const { isAuthenticated, user } = useAuth0();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <main>
          {isAuthenticated && <Header {...user} />}
          <Container>
            <Switch>
              <Route exact path='/'>
                {isSetupComplete ? <Questionnaire /> : <ProjectSetup />}
              </Route>
              <Route path='/project-details'>
                <ProjectSetup />
              </Route>
              <Route path='/existing-sensors'>
                <QuestionForm name='existing_sensors' />
              </Route>
              <Route path='/new-sensors'>
                <QuestionForm name='new_sensors' />
              </Route>
              <Route path='/acc-general-data'>
                <QuestionForm name='acc_general_data' />
              </Route>
              <Route path='/acc-components'>
                <QuestionForm name='acc_components' />
              </Route>
              <Route path='/steam-parameters'>
                <QuestionForm name='steam_parameters' />
              </Route>
              <Route path='/ambient-parameters'>
                <QuestionForm name='ambient_parameters' />
              </Route>
              <Route path='/condensate-parameters'>
                <QuestionForm name='condensate_parameters' />
              </Route>
              <Route path='/fan-parameters'>
                <QuestionForm name='fan_parameters' />
              </Route>
              <Route path='/acc-history'>
                <QuestionForm name='acc_history' />
              </Route>
              <Route path='/submission'>
                <Submission />
              </Route>
            </Switch>
          </Container>
        </main>
      </ThemeProvider>
    </Router>
  );
}

export default App;
