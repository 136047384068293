import * as Yup from 'yup';

let NewSensorsSchema = Yup.object().shape({
  monitoring_sensors: Yup.string(),
  voltage_type: Yup.string(),
  communication_type: Yup.string(),
  communication_type_notes: Yup.string()
    .nullable()
    .when('communication_type', {
      is: 'others',
      then: Yup.string()
        .required('Please specify a communication type')
        .min(2, 'Communication type cannot be less than 2 characters')
        .max(255, 'Communication type cannot be more than 20characters'),
    }),
});

export default NewSensorsSchema;
