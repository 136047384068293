export const fan_parameters = {
  title: 'Fan and Other Parameters',
  saveLabel: 'Save',
  updateLabel: 'Update',
  questions: [
    {
      title: '8.1 Fan Status',
      forms: [
        {
          type: 'text',
          description: '1. Please specify the below parameters for Fan status.',
          fields: [
            { key: 'status_frequency', label: 'Data Frequency' },
            { key: 'status_spot_value', label: 'Spot or Aggregated Value' },
            { key: 'status_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'status_aggregation_rules',
          label: 'Aggregation rules',
          key: 'status_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '8.2 Fan Power Consumption or Current',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Fan Power Consumption or Current.',
          fields: [
            { key: 'current_frequency', label: 'Data Frequency' },
            {
              key: 'current_power_spot_value',
              label: 'Spot or Aggregated Value',
            },
            { key: 'current_power_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'current_power_aggregation_rules',
          label: 'Aggregation rules',
          key: 'current_power_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '8.3 Suction Pressure (@vacuum system)',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Suction Pressure at vacuum system.',
          fields: [
            { key: 'suction_pressure_frequency', label: 'Data Frequency' },
            {
              key: 'suction_pressure_spot_value',
              label: 'Spot or Aggregated Value',
            },
            { key: 'suction_pressure_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'suction_pressure_aggregation_rules',
          label: 'Aggregation rules',
          key: 'suction_pressure_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '8.4 Suction Temperature (@vacuum system)',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Suction Temperature at vacuum system.',
          fields: [
            { key: 'suction_temperature_frequency', label: 'Data Frequency' },
            {
              key: 'suction_temperature_spot_value',
              label: 'Spot or Aggregated Value',
            },
            { key: 'suction_temperature_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'suction_temperature_aggregation_rules',
          label: 'Aggregation rules',
          key: 'suction_temperature_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '8.5 Attachments',
      forms: [
        {
          type: 'upload',
          name: 'fan_parameters_attachments',
          description:
            '1. Please attach any supporting documents for Fan Parameters',
          label: 'Upload',
        },
      ],
    },
  ],
};
