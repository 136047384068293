import ExistingSensorsSchema from './existing-sensors.schema';
import NewSensorsSchema from './new-sensors.schema';
import AccGeneralDataSchema from './acc_general_data.schema';
import AccComponentsSchema from './acc-components.schema';
import SteamParametersSchema from './steam-parameters.schema';
import AmbientParametersSchema from './ambient-parameters.schema';
import CondensateParametersSchema from './condensate-parameters.schema';
import FanParametersSchema from './fan-parameters.schema';
import AccHistorySchema from './acc-history.schema';
import SubmissionSchema from './submission.schema';

const schema = {
  existing_sensors: ExistingSensorsSchema,
  new_sensors: NewSensorsSchema,
  acc_general_data: AccGeneralDataSchema,
  acc_components: AccComponentsSchema,
  steam_parameters: SteamParametersSchema,
  ambient_parameters: AmbientParametersSchema,
  condensate_parameters: CondensateParametersSchema,
  fan_parameters: FanParametersSchema,
  acc_history: AccHistorySchema,
  submission: SubmissionSchema,
};

export default schema;
