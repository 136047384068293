export const content = {
  submitLabel: 'submit',
  frozenLabel: 'Frozen',
  headers: {
    title: 'Steam Cycle Advanced Analytics',
    description: 'Contact us',
    home: 'Home',
  },
  setup: {
    saveTitle: 'Project Setup',
    updateTitle: 'Project Details',
    saveAction: 'Create',
    updateAction: 'Update',
    introduction: {
      title: 'Introduction to ACC360<sup>&reg;</sup>',
      description:
        'The Onboarding Questionnaire is an integral part of the Foundation and Data Integration phases as outlined in the implementation of the ACC360<sup>&reg;</sup> solution.',
      subtitle: 'Primary Objectives',
      features: [
        '1. Identify an optimum connectivity and data integration methodology specific to the asset and its governing corporate policies.',
        '2. Receive a technical overview of the design and process of the ACC installation.',
        '3. Obtain an in-depth understanding of the existing ACC components and assess availability of measurements.',
        '4. Enable the compilation of required tags based on the information provided.',
      ],
    },
    general: {
      title: 'General Information',
      label: {
        name: 'Plant name',
        location: 'Site location',
        lead_name: 'Plant manager',
      },
      placeholder: {
        location: 'City, Country',
      },
    },
    contact: {
      title: 'Contact Details',
      departments: [
        { department: 'operations', value: 'Operations' },
        { department: 'maintenance', value: 'Maintenance' },
        { department: 'connectivity', value: 'IT / Connectivity' },
        { department: 'logistics', value: 'Logistics' },
      ],
      label: {
        name: 'Name',
        email: 'Email',
        phone: 'Telephone',
      },
    },
  },
  questionnaire: {
    title: 'Onboarding Questionnaire',
    action: 'Save',
    submitLabel: 'Submit',
    uploadLabel: 'Upload',
    cards: [
      {
        title: 'Connectivity with Existing Sensors',
        path: '/existing-sensors',
        name: 'existing_sensors',
        existing_sensors: {
          data_historian: {
            title: '1.1 Data Historian',
            question:
              'Please specify the Data Historian to be installed or currently in use',
            option1: 'OSIsoft PI',
            option1_reference:
              'A connection can be established with the PI System through WebAPI',
            option1_reference_link:
              'http://documentation.sas.com/api/docsets/acpi/9.4/content/acpi.pdf?locale=en',
            option2: 'Aspentech IP21',
            option2_reference:
              'A connection can be established through ODBC (http://support.sas.com/documentation/cdl/en/acreldb/63647/HTML/default/viewer.htm#a001355229.htm). This requires the installation of the “Aspen Tech SQL Plus ODBC driver” on the IP21 server.',
            option3: 'GE Proficy',
            option3_reference:
              'Almost all data historians allow for access through either an odbc connection or Web API. Vendor user manuals can provide further information on this.',
            option4: 'Sturctured Database (MySQL, Teradata, etc.,)',
            option4_reference:
              'Connection can be established to a database server. In this case, real-time data from the historian/server is loaded into a structured database that is set up within the local network. Remote access is then granted to the database only.',
            option5: 'Other',
          },
          connection_parameters: {
            title: '1.2 Connection Parameters',
            question: 'What connection parameter should we use?',
            server_name: 'Server Name',
            host_name: 'Host Name',
            user_name: 'User Name',
            password: 'Password',
            retype_password: 'Retype Password',
            authentication_method: 'Please specify authentication method',
            option1: 'Basic',
            option2: 'Kerberos',
            option3: 'Token',
            option4: 'Other',
          },
          scripts: {
            title: '1.3 Scripts',
            question:
              'Are there any example script available on how to access the data?',
            no: 'No',
            yes: 'Yes',
          },
          handling: {
            title: '1.4 Handling',
            question:
              'Are there any limitations in the amount of data that can be extracted at once?',
            no: 'No',
            yes: 'Yes',
          },
        },
      },
      {
        title: 'Connectivity with New Sensors',
        path: '/new-sensors',
        name: 'new_sensors',
        new_sensors: {
          condition_monitoring: {
            title: '2.1 Condition Monitoring Sensors',
            question1:
              'Are there any vibration sensors (such as accelerometers) installed currently on any of the ACC components?',
            question2:
              'Are there any other types of sensors (such as temperature) installed on any of the ACC components?',
            no: 'No',
            yes: 'Yes',
          },
          power_supply: {
            title: '2.2 Power Supply',
            question:
              'Is power supply available in proximity to the ACC modules?',
            option1: '110V',
            option2: '220V',
            option3: 'N/A',
          },
          communication: {
            title: '2.3 Communication',
            question: 'That is the type of communication available on site?',
            option1: '3G',
            option2: '4G',
            option3: 'LORA',
            option4: 'Ethernet',
            option5: 'Other',
          },
        },
      },
      {
        title: 'ACC General Data',
        path: '/acc-general-data',
        name: 'acc_general_data',
        general_data: {
          main_datasheet: {
            title: '3.1 ACC Main Datasheet',
            question: 'Please attach.',
            no: 'No',
            yes: 'Yes',
          },
          performnce_curve: {
            title: '3.2 ACC Performnce Curve',
            question: 'Please attach.',
            no: 'No',
            yes: 'Yes',
          },
          pid: {
            title: '3.3 P&ID',
            question:
              'Please attach. Can the tags from the DCS be directly mapped to the P&ID tags? ',
            no: 'No',
            yes: 'Yes',
          },
          power_plant_layout: {
            title: '3.4 Power Plant Layout',
            question: 'Please attach.',
            no: 'No',
            yes: 'Yes',
          },
          steam_turbine_curve: {
            title: '3.5 Steam Turbine Curve',
            question:
              'Please attach set of curves at different steam flows i.e. steam turbine output as a function of back presure at different steamflows',
            question2:
              'Please also attach the steam turbine trip and alaram curve. If not available, please provide the turbine trip and alaram levels (in mbar)',
            no: 'No',
            yes: 'Yes',
          },
          heat_mass_balance: {
            title: '3.6 Heat and Mass Balance',
            question:
              'Please provide turbine isentropic effiency and releated heat & mass balance diagram',
            no: 'No',
            yes: 'Yes',
          },
          fan_control_step: {
            title: '3.7 Fan Control Step',
            question: 'Please attach.',
            no: 'No',
            yes: 'Yes',
          },
          dcs_screenshots: {
            title: '3.8 DCS Screenshots',
            question:
              'Please attach screenshots of the complete stream cycle to enable SPG DC collate the required tags (see also 5. Required Parameters). SPG DC will required a list of tags after analysis of this document and its annexes',
            no: 'No',
            yes: 'Yes',
          },
          maintenance_activities: {
            title: '3.9 Maintenance Activities',
            question:
              'Please attach a lsit of ACC cleaning records and maintenance activies',
            no: 'No',
            yes: 'Yes',
          },
        },
      },
      {
        title: 'ACC Components',
        path: '/acc-components',
        name: 'acc_components',
        components: {
          gearbox: {
            title: '4.1 Gearbox',
            manufacturer: 'Manufacturer',
            gear_ratio: 'Gear Ratio',
            teeth_per_gear: 'Number of Teeth Per Gear',
            bearing_types: 'Bearing Types',
            attachment: 'Attach Datasheet & Drawing',
          },
          motor: {
            title: '4.2 Motor',
            manufacturer: 'Manufacturer',
            power_consumption: 'Power Consumption / Current (%, Amps, kW)',
            speed: 'Speed',
            single_speed: 'Single Speed',
            two_speed: '2-Speed',
            variable_speed: 'Variable Speed',
            rpm: 'Specify Speed in RPM',
            bearing_types: 'Bearing Types',
            attachment: 'Attach Datasheet & Drawing',
          },
          fan: {
            title: '4.3 Fan',
            manufacturer: 'Manufacturer',
            type: 'Type',
            size: 'Size (in feet)',
            blades: 'Number of Blades',
            directorcoupling: 'Directly Fixed or Through Coupling',
            coupling_type: 'Coupling Type',
            attachment: 'Attach Datasheet & Drawing',
          },
          vacuum_system: {
            title: '4.4 Vacuum System',
            manufacturer: 'Manufacturer',
            general_configuration: 'General Configuration',
            steam_value_status: 'Steam Ejector - Valve Status',
            steam_motive_steam_pressure:
              'Steam Ejector - Motive Steam Pressure',
            steam_motive_steam_temperature:
              'Steam Ejector - Motive Steam Temperature',
            steam_suction_pressure: 'Steam Ejector - Suction Pressure',
            steam_suction_temperature: 'Steam Ejector - Suction Temperature',
            vacuum_pump_status: 'Vacuum Pumps - Pump Status',
            vacuum_valve_status: 'Vacuum Pumps - Valve Status',
            vacuum_suction_pressure: 'Vacuum Pumps - Suction Pressure',
            vacuum_suction_temperature: 'Vacuum Pumps - Suction Temperature',
            vacuum_cooling_water: 'Vacuum Pumps - Cooling Water Temperature',
            attachment: 'Attach Datasheet & Drawing',
            notavailable: 'Not Available',
            available: 'Available',
          },
          drain_pumps: {
            title: '4.5 Drain Pumps',
            manufacturer: 'Manufacturer',
            status: 'Status',
            pump_volume_flow: 'Pump Volume Flow',
            attachment: 'Attach Datasheet',
            notavailable: 'Not Available',
            available: 'Available',
          },
          drain_pot: {
            title: '4.6 Drain Pot',
            level: 'Level',
            temperature: 'Temperature',
            volume: 'Volume (datasheet Value)',
            attachment: 'Attach Datasheet & Drawing',
            notavailable: 'Not Available',
            available: 'Available',
          },
          condensate_extraction_pumps: {
            title: '4.7 Condensate Extraction Pumps',
            manufacturer: 'Manufacturer',
            status: 'Status',
            pump_volume_flow: 'Pump Volume Flow',
            attachment: 'Attach Datasheet',
            notavailable: 'Not Available',
            available: 'Available',
          },
          condensate_tank: {
            title: '4.8 Condensate Tank',
            level: 'Level',
            temperature: 'Temperature',
            volume: 'Volume (Datasheet Value)',
            attachment: 'Attach Datasheet & Drawing',
            notavailable: 'Not Available',
            available: 'Available',
          },
          cleaning_pump: {
            title: '4.9 Cleaning Pump',
            manufacturer: 'Manufacturer',
            status: 'Status',
            actual_flow_value: 'Actual Flow Value',
            question:
              'Is the cleaning pump solely dedicated for cleaning or is it used for any additional purpose?',
            attachment: 'Attach Datasheet',
            notavailable: 'Not Available',
            available: 'Available',
          },
          fogging_system: {
            title: '4.10 Fogging System',
            status: 'Status',
            actual_flow_value: 'Actual Flow Value',
            question:
              'Is the fogging system solely dedicated to fogging or is it used for any additional purpose?',
            available: 'Available',
            notavailable: 'Not Available',
            notapplicable: 'Not Applicable',
          },
          bypass_valves: {
            title: '4.11 Bypass Valves',
            status: 'Status',
            measurement_type: 'Type of Measurement',
            available: 'Available',
            notavailable: 'Not Available',
            open_close: 'Open/Close',
            position: 'Position',
          },
          wind_mitigation: {
            title: '4.12 Wind Mitigation Device',
            question:
              'Have you installed any system or device for wind mitigation?',
            question2:
              'If present, what is the type of wind mitigation device that you have?',
            attachment: 'Attach Drawing',
            no: 'No',
            yes: 'Yes',
            fixed: 'Fixed',
            retractable: 'Retractable',
            other: 'Other',
          },
          anemometer_wind: {
            title: '4.13 Anemometer/Wind Measurement Device',
            question:
              'What is the location and elevation of your wind measurement device?',
            question2:
              'What is the reference for wind direction? Is 0 degree the True North?',
            elevation: 'Elevation',
            location: 'Location',
            plant_layout: 'Please indicate on plant layout',
          },
        },
      },
      {
        title: 'Steam Parameters',
        path: '/steam-parameters',
        name: 'steam_parameters',
        parameter_steam: {
          steam_flow: {
            title: '5.1 Steam Flow',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
            steam_flow_calculation:
              'How is the steam flow calculated in the DCS? Is the steam flow calculated on the steam side (before the steam turbine) or on the condensate pump side (after the condensate extraction pumps)? Please provide all relevant flows, values and extraction information.',
          },
          steam_temperature: {
            title: '5.2 Steam Temperature',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
            location: 'Please specify location:',
            turbine_exhaust: 'Turbine Exhaust',
            main_duct: 'Main Duct',
            acc_Mainfold_Inlet: 'ACC Manifold Inlet',
            other: 'Other',
          },
          steam_pressure: {
            title: '5.3 Steam Pressure',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
            location: 'Please specify location:',
            turbine_exhaust: 'Turbine Exhaust',
            main_duct: 'Main Duct',
            acc_Mainfold_Inlet: 'ACC Manifold Inlet',
            other: 'Other',
          },
          steam_turbine_output: {
            title: '5.4 Steam Turbine Output',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
            question:
              'Please provide related tag or calculation if available, If not, please provide oerall power plant output',
          },
          attachment: {
            title: '5.5 Attachments',
            attach: 'Please attach relavent files any',
          },
        },
      },
      {
        title: 'Ambient Parameters',
        path: '/ambient-parameters',
        name: 'ambient_parameters',
        parameters_ambient: {
          ambient_temperature: {
            title: '6.1 Ambient Temperature',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
            location: 'Please specify location:',
            acc_inlet: 'ACC Inlet',
            gas_turbine_inlet: 'Gas Turbine Inlet',
            weather_station: 'Weather Station',
            other: 'Other',
          },
          ambient_pressure: {
            title: '6.2 Ambient Pressure',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
          },
          ambient_humidity: {
            title: '6.3 Ambient Humidity',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
          },
          wind_speed: {
            title: '6.4 Wind Speed',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
          },
          wind_direction: {
            title: '6.5 Wind Direction',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
          },
          attachment: {
            title: '6.6 Attachments',
            attach: 'Please attach relavent files any',
          },
        },
      },
      {
        title: 'Condensate Parameters',
        path: '/condensate-parameters',
        name: 'condensate_parameters',
        parameters_condensate: {
          ct_manifolds: {
            title: '7.1 Condensate Temperatures (@manifolds)',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
          },
          ct_condensate_tank: {
            title: '7.2 Condensate Temperatures (@condensate tank)',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
          },
          air_takeoff: {
            title: '7.3 Air Take-off Temperatures (@extraction)',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
          },
          bypass_valve: {
            title: '7.4 Bypass Valve Position',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
            question: 'Please specify for:',
            highpressure: 'High Pressure',
            intermediatepressure: 'Intermediate Pressure',
            lowpressure: 'Low Pressure',
          },
          attachment: {
            title: '7.5 Attachments',
            attach: 'Please attach relavent files any',
          },
        },
      },
      {
        title: 'Fan and Other Parameters',
        path: '/fan-parameters',
        name: 'fan_parameters',
        parameters_fan: {
          fan_status: {
            title: '8.1 Fan Status',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
          },
          fan_power: {
            title: '8.2 Fan Power Consumption or Current',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
          },
          suction_pressure: {
            title: '8.3 Suction Pressure (@vacuum system)',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
          },
          suction_temperature: {
            title: '8.4 Suction Temperature (@vacuum system)',
            frequency: 'Frequency of data recorded',
            spot_aggregate: 'Spot value or aggregated value',
            unit: 'Unit',
            aggregation_rules: 'Aggregation Rules',
          },
          attachment: {
            title: '8.5 Attachments',
            attach: 'Please attach relavent files any',
          },
        },
      },
      {
        title: 'ACC History',
        path: '/acc-history',
        name: 'acc_history',
        acc_history: {
          cleaning: {
            title: '9.1 Cleaning',
            question:
              'When was the ACC lsat cleaned? Please list last 5 cleaning dates',
            question2:
              'How is cleaning typically performed on the ACC? Do you implement this cell by cell or street by street? Please describe the methodology',
          },
          modification: {
            title: '9.2 Modification and Repair',
            question:
              'Have you performed any modification on the ACC after the initial installation?',
            question2: 'Have any of the following been replaced or altered?',
            gearbox: 'Gearbox',
            motor: 'Motor',
            fan: 'Fan',
            blade_angle: 'Blade Angle',
            other: 'Other',
          },
          issues: {
            title: '9.3 Issues',
            question:
              'Have you faced any major issues in ACC performance or reliability since its installation?',
          },
          attachment: {
            title: '9.4 Attachments',
            attach: 'Please attach relavent files any',
          },
        },
      },
    ],
  },
  submission: {
    title: 'Submission and Next Steps',
    subTitle: 'Terms and condition',
    description: [
      '1. Please complete the Questionnaire prior to submitting data to SPG DC.',
      '2. It is important that each section is completed to ensure that data is managed appropriately on receipt.',
      '3. Requested documents and attachments may be submitted in compressed form. Instructions for decompression must be included.',
      '4. SPG DC will confirm the receipt of the Onboarding Questionnaire and validate its completeness.',
      '5. System configuration and connection to ACC360<sup>&reg;</sup> will directly depend on the availability of data as indicated in the forms.',
      '6. A formal request of required tags will be issued by SPG DC upon processing of this form.',
    ],
    fields: [
      {
        key: 'submission_checkbox',
        label:
          'I agree that the information provided in Onboarding Questionnaire is true, correct and complete to the best of my knowledge.',
      },
    ],
    submitLabel: 'Submit and Notify',
    goBack: 'Go Back',
  },
};
