import * as Yup from 'yup';

let SubmissionSchema = Yup.object().shape({
  submission_checkbox: Yup.boolean().oneOf(
    [true],
    'Please accept the Terms of Use'
  ),
});

export default SubmissionSchema;
