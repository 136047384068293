import React, { useRef, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import logo from '../logo.gif';

import { Dialog } from './dialog.component';

import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  makeStyles,
  Typography,
  Avatar,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from '@material-ui/core';

import cms from '../cms/index';

import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Header = ({ picture }) => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const { logout } = useAuth0();

  const onLogout = () => {
    localStorage.removeItem('acc360.components');
    localStorage.removeItem('acc360.user');
    logout({ returnTo: window.location.origin });
  };

  const { title, items, menus } = cms.header;

  return (
    <AppBar position='static' color='transparent' className='onboarding-header'>
      <Toolbar>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='menu'
          onClick={() => history.push('/')}
        >
          <img src={logo} alt='spgdc' />
        </IconButton>
        <Typography variant='h6' className={classes.title}>
          {title}
        </Typography>
        <div className='action-items'>
          {items.map((item, index) =>
            item.key === 'contact' ? (
              <Dialog {...item} key={index} />
            ) : (
              <Button
                color='inherit'
                key={index}
                onClick={() => {
                  history.push(item.path);
                }}
              >
                {item.label}
              </Button>
            )
          )}
          <div>
            <IconButton
              ref={anchorRef}
              aria-controls={open ? 'onboarding-avatar-menu' : undefined}
              aria-haspopup='true'
              onClick={handleToggle}
            >
              <Avatar src={picture} />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id='onboarding-avatar-menu'
                      >
                        {menus.map((menu, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              if (menu.path) {
                                return history.push(menu.path);
                              }
                              onLogout();
                            }}
                          >
                            {menu.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
