import React from 'react';

import { Grid, TextField } from '@material-ui/core';

const TextArea = ({ name, handleChange, errors, touched, values }) => {
  return (
    <div className='onboarding-text-area'>
      <Grid item sm={4}>
        <TextField
          name={name}
          value={values[name]}
          multiline
          rowsMax={4}
          error={!!(errors[name] && touched[name])}
          onChange={handleChange}
          helperText={errors[name] && touched[name] ? errors[name] : null}
        />
      </Grid>
    </div>
  );
};

export default TextArea;
