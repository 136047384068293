import React from 'react';

import {
  FormControl,
  Checkbox,
  Tooltip,
  Grid,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';

const AppCheckbox = ({
  fields,
  handleChange,
  errors,
  touched,
  values,
  sm = 3,
}) => {
  return (
    <FormControl component='fieldset' className='onboarding-checkbox'>
      {fields.map(({ key, label, popover, link }, index) => (
        <Grid item sm={sm} key={index}>
          <FormControlLabel
            error={errors[key] && touched[key]}
            control={
              <Checkbox
                color='primary'
                onChange={handleChange}
                name={key}
                checked={values[key]}
              />
            }
            label={label}
          />
          <FormHelperText>
            {errors[key] && touched[key] ? errors[key] : null}
          </FormHelperText>
          {popover && <Tooltip {...{ name: key, popover, link }} />}
        </Grid>
      ))}
    </FormControl>
  );
};

export { AppCheckbox as Checkbox };
