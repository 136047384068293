import { all, call } from 'redux-saga/effects';

import {
  getProjectSaga,
  saveProjectSaga,
  submitProjectSaga,
} from './project-setup/project-setup.saga';
import { getComponentsSaga } from './questionnaire/questionnaire.saga';
import {
  getQuestionSaga,
  saveQuestionsSaga,
  uploadAssetSaga,
  deleteAssetSaga,
  downloadAssetSaga,
} from './questions/questions.saga';

export default function* rootSaga() {
  yield all([
    call(getProjectSaga),
    call(saveProjectSaga),
    call(submitProjectSaga),
    call(getComponentsSaga),
    call(getQuestionSaga),
    call(saveQuestionsSaga),
    call(uploadAssetSaga),
    call(deleteAssetSaga),
    call(downloadAssetSaga),
  ]);
}
