import * as Yup from 'yup';

let FanParametersSchema = Yup.object().shape({
    status_frequency: Yup.string()
        .max(50, 'Data frequency cannot be more than 50 characters'),
    status_spot_value: Yup.string()
        .max(50, 'Spot value cannot be more than 50 characters'),
    status_unit: Yup.string()
        .max(50, 'Unit cannot be more than 50 characters'),
    status_aggregation_rules: Yup.string()
        .max(255, 'Aggregation rules cannot be more than 255 characters'),
    current_frequency: Yup.string()
        .max(50, 'Data frequency cannot be more than 50 characters'),
    current_spot_value: Yup.string()
        .max(50, 'Spot value cannot be more than 50 characters'),
    current_unit: Yup.string()
        .max(50, 'Unit cannot be more than 50 characters'),
    current_aggregation_rules: Yup.string()
        .max(255, 'Aggregation rules cannot be more than 255 characters'),
    suction_pressure_frequency: Yup.string()
        .max(50, 'Data frequency cannot be more than 50 characters'),
    suction_pressure_spot_value: Yup.string()
        .max(50, 'Spot value cannot be more than 50 characters'),
    suction_pressure_unit: Yup.string()
        .max(50, 'Unit cannot be more than 50 characters'),
    suction_pressure_aggregation_rules: Yup.string()
        .max(255, 'Aggregation rules cannot be more than 255 characters'),
    suction_temperature_frequency: Yup.string()
        .max(50, 'Data frequency cannot be more than 50 characters'),
    suction_temperature_spot_value: Yup.string()
        .max(50, 'Spot value cannot be more than 50 characters'),
    suction_temperature_unit: Yup.string()
        .max(50, 'Unit cannot be more than 50 characters'),
    suction_temperature_aggregation_rules: Yup.string()
        .max(255, 'Aggregation rules cannot be more than 255 characters'),
});

export default FanParametersSchema;
