export const existing_sensors = {
  title: 'Connectivity with Existing Sensors',
  saveLabel: 'Save',
  updateLabel: 'Update',
  questions: [
    {
      title: '1.1 Data Historian',
      forms: [
        {
          type: 'radio',
          description:
            '1. Please specify the data historian to be installed or currently in use.',
          name: 'historian_type',
          other: 'historian_type_notes',
          fields: [
            {
              key: 'osiSoftPI',
              label: 'OSISoft PI',
              popover:
                'A connection can be established with the <a href="http://documentation.sas.com/api/docsets/acpi/9.4/content/acpi.pdf?locale=en" target="_blank">PI System</a> through a WebAPI.',
            },
            {
              key: 'aspentechIP21',
              label: 'Aspentech IP21',
              popover:
                'A connection can be established through ODBC. This requires the installation of the <a href="http://support.sas.com/documentation/cdl/en/acreldb/63647/HTML/default/viewer.htm#a001355229.htm" target="_blank">Aspen Tech SQL Plus ODBC driver</a> on the IP21 server.',
            },
            {
              key: 'geProficy',
              label: 'GE Proficy',
              popover:
                'Almost all data historians allow for access through either an ODBC connection or Web API. Vendor user manuals can provide further information on this.',
            },
            {
              key: 'database',
              label: 'Database (MySQL, Teradata,...)',
              popover:
                'Connection can be established to a database server. In this case, real-time data from the historian/server is loaded into a structured database that is set up within the local network. Remote access is then granted to the database only.',
            },
          ],
        },
      ],
    },
    {
      title: '1.2 Connection Parameters',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the parameters to establish a connection.',
          fields: [
            { key: 'server_name', label: 'Server name' },
            { key: 'host_name', label: 'Host name' },
            { key: 'user_name', label: 'Username' },
            {
              key: 'password',
              label: 'Password',
            },
            {
              key: 'confirm_password',
              label: 'Confirm Password',
            },
          ],
        },
        {
          type: 'radio',
          description: '2. Please specify an authentication type.',
          name: 'authentication_type',
          other: 'authentication_type_notes',
          fields: [
            { key: 'basic', label: 'Basic' },
            { key: 'kerberos', label: 'Kerberos' },
            { key: 'token', label: 'Token' },
          ],
        },
        {
          type: 'upload',
          name: 'connection_parameters',
          description:
            '3. Please attach if there are any documents available on how to connect to the systems.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '1.3 Scripts',
      forms: [
        {
          type: 'upload',
          name: 'scripts',
          description:
            '1. Please attach any example script on how to access the data.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '1.4 Handling / Limitations',
      forms: [
        {
          type: 'textarea',
          name: 'limitations',
          description:
            '1. Please let us know if there any limitations in handling the data.',
        },
      ],
    },
  ],
};
