import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Card, Button, CardContent, Grid } from '@material-ui/core';

import { Formik } from 'formik';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { content } from '../../content';
import ProjectSetupSchema from './project-setup.schema';
import Introduction from '../../components/project-setup/introduction';
import GeneralDetails from '../../components/project-setup/general-details';
import ContactDetails from '../../components/project-setup/contact-details';
import {
  SAVE_PROJECT,
  GET_PROJECT,
} from '../../redux/project-setup/project-setup.types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
      width: '200px',
    },
  },
}));

export const ProjectSetup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();

  const {
    setup: {
      saveTitle,
      updateTitle,
      saveAction,
      updateAction,
      introduction,
      general,
      contact,
    },
  } = content;

  const { project, isLoading } = useSelector((state) => state.projectSetup);

  useEffect(() => {
    let payload = { ...user };
    const setUser = async () => {
      try {
        if (isLoading) {
          payload.accessToken = await getAccessTokenSilently({
            audience: localStorage.getItem('acc360.audience'),
          });
          localStorage.setItem('acc360.user', JSON.stringify(payload));
          dispatch({ type: GET_PROJECT });
        }
      } catch (err) {
        console.error(err);
      }
    };
    setUser();
  });

  return (
    !isLoading && (
      <section>
        <header>
          <h2>{project.id ? updateTitle : saveTitle}</h2>
        </header>
        <Formik
          enableReinitialize={true}
          initialValues={project}
          validationSchema={ProjectSetupSchema}
          onSubmit={(values) => {
            dispatch({
              type: SAVE_PROJECT,
              payload: { ...values },
            });
            if (project.id) {
              history.push('/');
            }
          }}
        >
          {(props) => (
            <Card className='onboarding-form'>
              <CardContent>
                <Grid container spacing={7}>
                  <Grid item sm={5}>
                    <Introduction {...introduction} />
                  </Grid>
                  <Grid item sm={7}>
                    <GeneralDetails {...general} {...props} classes={classes} />
                    <ContactDetails {...contact} {...props} classes={classes} />
                    <Button
                      onClick={props.handleSubmit}
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {project.id ? updateAction : saveAction}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Formik>
      </section>
    )
  );
};

export default withAuthenticationRequired(ProjectSetup);
