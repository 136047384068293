import React from 'react';

import { Tooltip, withStyles } from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#595d60',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #595d60',
  },
}))(Tooltip);

const AppTooltip = ({ name, popover, link }) => {
  return (
    <HtmlTooltip
      title={<p dangerouslySetInnerHTML={{ __html: popover }}></p>}
      interactive
      className='onboarding-tooltip'
    >
      <InfoIcon>{name}</InfoIcon>
    </HtmlTooltip>
  );
};

export { AppTooltip as Tooltip };
