import React from 'react';

import { FieldArray, Form } from 'formik';

import { TextField } from '@material-ui/core';

const ContactDetails = ({
  title,
  departments,
  label,
  errors,
  handleChange,
  touched,
  classes,
  values,
}) => {
  return (
    <div className='question-form'>
      <h4>{title}</h4>
      <Form className={classes.root}>
        <FieldArray
          name='project_contacts'
          render={() => (
            <>
              {departments.map(({ department, value }, outerIndex) => (
                <div key={outerIndex}>
                  <h5>{value}</h5>
                  {Object.entries(label).map(([key, value], index) => {
                    const name = `project_contacts[${outerIndex}].${key}`;
                    const error =
                      errors.project_contacts &&
                      errors.project_contacts[outerIndex] &&
                      errors.project_contacts[outerIndex][key];
                    const touch =
                      touched.project_contacts &&
                      touched.project_contacts[outerIndex] &&
                      touched.project_contacts[outerIndex][key];
                    const formData =
                      values.project_contacts &&
                      values.project_contacts.find(
                        (contact) => contact.department === department
                      );
                    const formValue = formData && formData[key];
                    return (
                      <TextField
                        error={!!(error && touch)}
                        value={formValue}
                        name={name}
                        onChange={handleChange}
                        label={value}
                        key={index}
                        helperText={error && touch ? error : null}
                      />
                    );
                  })}
                </div>
              ))}
            </>
          )}
        />
      </Form>
    </div>
  );
};

export default ContactDetails;
