export const condensate_parameters = {
  title: 'Condensate Parameters',
  saveLabel: 'Save',
  updateLabel: 'Update',
  questions: [
    {
      title: '7.1 Condensate Temperatures (@manifolds)',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Condensate Temperature at Manifolds.',
          fields: [
            { key: 'manifolds_frequency', label: 'Data Frequency' },
            { key: 'manifolds_spot_value', label: 'Spot or Aggregated Value' },
            { key: 'manifolds_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'manifolds_aggregation_rules',
          label: 'Aggregation rules',
          key: 'manifolds_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '7.2 Condensate Temperatures (@condensate tank)',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Condensate Temperature at Condensate Tank.',
          fields: [
            { key: 'condensate_tank_frequency', label: 'Data Frequency' },
            {
              key: 'condensate_tank_spot_value',
              label: 'Spot or Aggregated Value',
            },
            { key: 'condensate_tank_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'condensate_tank_aggregation_rules',
          label: 'Aggregation rules',
          key: 'condensate_tank_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '7.3 Air Take-off Temperatures (@extraction)',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Condensate Temperature at Extraction.',
          fields: [
            { key: 'extraction_frequency', label: 'Data Frequency' },
            { key: 'extraction_spot_value', label: 'Spot or Aggregated Value' },
            { key: 'extraction_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'extraction_aggregation_rules',
          label: 'Aggregation rules',
          key: 'extraction_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '7.4 Bypass Valve Position',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Bypass Valve Position.',
          fields: [
            { key: 'bypass_frequency', label: 'Data Frequency' },
            { key: 'bypass_spot_value', label: 'Spot or Aggregated Value' },
            { key: 'bypass_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'bypass_aggregation_rules',
          label: 'Aggregation rules',
          key: 'bypass_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
        {
          type: 'checkbox',
          description:
            '3. Please specify the available parameters for Bypass Valve.',
          name: 'bypass_parameters',
          fields: [
            {
              key: 'high_pressure',
              label: 'High Pressure',
            },
            {
              key: 'intermediate_pressure',
              label: 'Intermediate Pressure',
            },
            {
              key: 'low_pressure',
              label: 'Low Pressure',
            },
          ],
        },
      ],
    },
    {
      title: '7.5 Attachments',
      forms: [
        {
          type: 'upload',
          name: 'condensate_parameters_attachments',
          description:
            '1. Please attach any supporting documents for Condensate Parameters.',
          label: 'Upload',
        },
      ],
    },
  ],
};
