import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Button } from '@material-ui/core';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import FileUpload from '../../components/form-elements/file-upload';

const UploadInput = ({ name, label, component_id, componentName }) => {
  const state = useSelector((state) => state.questions);
  const { assets } = state[componentName];

  const [files, setFiles] = useState([]);

  return (
    <div className='onboarding-upload'>
      <input
        multiple
        {...{
          type: 'file',
          name,
          id: name,
          accept:
            '.xls,.xlsx,.doc,.docx,.ppt,.pptx,.csv,.pdf,.txt,.json,image/*',
          onChange(event) {
            setFiles(Array.from(event.target.files));
          },
        }}
      />
      <label htmlFor={name}>
        <Button
          variant='outlined'
          color='primary'
          startIcon={<CloudUploadIcon />}
          component='span'
        >
          {label}
        </Button>
      </label>
      {assets
        .filter((asset) => asset.category === name)
        .map((file, index) => (
          <FileUpload {...{ name, component_id, file }} key={index} />
        ))}
      {files.map((file, index) => (
        <FileUpload {...{ name, component_id, file }} key={index} />
      ))}
    </div>
  );
};

export default UploadInput;
