export const GET_QUESTION = 'GET_QUESTION';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_FAILURE = 'GET_QUESTION_FAILURE';
export const SAVE_QUESTION = 'SAVE_QUESTION';
export const SAVE_QUESTION_SUCCESS = 'SAVE_QUESTION_SUCCESS';
export const SAVE_QUESTION_FAILURE = 'SAVE_QUESTION_FAILURE';
export const UPLOAD_ASSET_SUCCESS = 'UPLOAD_ASSET_SUCCESS';
export const UPLOAD_ASSET_FAILURE = 'UPLOAD_ASSET_FAILURE';
export const UPLOAD_ASSET = 'UPLOAD_ASSET';
export const DOWNLOAD_ASSET_FAILURE = 'DOWNLOAD_ASSET_FAILURE';
export const DOWNLOAD_ASSET = 'DOWNLOAD_ASSET';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_FAILURE = 'DELETE_ASSET_FAILURE';
export const DELETE_ASSET = 'DELETE_ASSET';
