export const new_sensors = {
  title: 'Connectivity with New Sensors',
  saveLabel: 'Save',
  updateLabel: 'Update',
  questions: [
    {
      title: '2.1 Condition-Monitoring Sensors',
      forms: [
        {
          type: 'textarea',
          name: 'monitoring_sensors',
          description:
            '1. Please specify the sensors (vibration, temperature, ...) already installed on the ACC components.',
        },
        {
          type: 'upload',
          name: 'monitoring_sensors_assets',
          description:
            '2. Please attach documents on condition-monitoring sensors if available.',
          label: 'Upload',
        },
      ],
    },
    {
      title: '2.2 Power Supply',
      forms: [
        {
          type: 'radio',
          description:
            '1. Please specify the type of power supply available on site.',
          name: 'voltage_type',
          fields: [
            { key: '110', label: '110 V' },
            { key: '220', label: '220 V' },
          ],
        },
      ],
    },
    {
      title: '2.3 Communication',
      forms: [
        {
          type: 'radio',
          description:
            '1. Please specify the type of network communication available on site.',
          name: 'communication_type',
          other: 'communication_type_notes',
          fields: [
            { key: '3g', label: '3G' },
            { key: '4g', label: '4G' },
            { key: 'lora', label: 'Lora' },
            { key: 'ethernet', label: 'Ethernet' },
          ],
        },
        {
          type: 'upload',
          name: 'communication_type_assets',
          description:
            '2. Please attach documents to establish communication on site if available.',
          label: 'Upload',
        },
      ],
    },
  ],
};
