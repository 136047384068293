import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card, CardContent, Grid, Button, Divider } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { content } from '../content';
import { Checkbox } from './form-elements/checkbox';
import { SUBMIT_PROJECT } from '../redux/project-setup/project-setup.types';
import schema from '../schema/index';

const Submission = () => {
  const { isSubmitted } = useSelector((state) => state.projectSetup);
  const {
    submission: { title, subTitle, description, fields, submitLabel, goBack },
  } = content;

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSubmitted) {
      history.push('/');
    }
  }, [isSubmitted, history]);

  const onGoBack = () => {
    history.push('/');
  };

  return (
    <section className='onboarding-submission'>
      <header>
        <h2>{title}</h2>
      </header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          submission_checkbox: false,
        }}
        validationSchema={schema.submission}
        onSubmit={() => {
          dispatch({
            type: SUBMIT_PROJECT,
          });
        }}
      >
        {({ handleSubmit, isSubmitting, ...props }) => (
          <Grid container>
            <Grid item sm={12}>
              <Card className='onboarding-form'>
                <CardContent>
                  <header>
                    <h4>{subTitle}</h4>
                  </header>
                  <Divider />
                  {description.map((desc) => (
                    <h5 dangerouslySetInnerHTML={{ __html: desc }} />
                  ))}
                  <Divider />
                  <Form>
                    <Checkbox {...props} fields={fields} sm={12} />
                  </Form>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12}>
              <Button
                color='primary'
                variant='contained'
                onClick={handleSubmit}
              >
                {submitLabel}
              </Button>
              <Button color='primary' variant='outlined' onClick={onGoBack}>
                {goBack}
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
    </section>
  );
};

export default Submission;
