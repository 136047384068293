import * as Yup from 'yup';

let AmbientParametersSchema = Yup.object().shape({
  temperature_location: Yup.string(),
  temperature_location_notes: Yup.string()
    .nullable()
    .when('temperature_location', {
      is: 'others',
      then: Yup.string()
        .required('Please specify the source location')
        .min(2, 'Source location cannot be less than 2 characters')
        .max(100, 'Source location cannot be more than 100 characters'),
    }),
  temperature_frequency: Yup.string()
    .max(50, 'Data frequency cannot be more than 50 characters'),
  temperature_spot_value: Yup.string()
    .max(50, 'Spot value cannot be more than 50 characters'),
  temperature_unit: Yup.string()
    .max(50, 'Unit cannot be more than 50 characters'),
  temperature_aggregation_rules: Yup.string()
    .max(255, 'Aggregation rules cannot be more than 255 characters'),
  pressure_frequency: Yup.string()
    .max(50, 'Data frequency cannot be more than 50 characters'),
  pressure_spot_value: Yup.string()
    .max(50, 'Spot value cannot be more than 50 characters'),
  pressure_unit: Yup.string()
    .max(50, 'Unit cannot be more than 50 characters'),
  pressure_aggregation_rules: Yup.string()
    .max(255, 'Aggregation rules cannot be more than 255 characters'),
  humidity_frequency: Yup.string()
    .max(50, 'Data frequency cannot be more than 50 characters'),
  humidity_spot_value: Yup.string()
    .max(50, 'Spot value cannot be more than 50 characters'),
  humidity_unit: Yup.string()
    .max(50, 'Unit cannot be more than 50 characters'),
  humidity_aggregation_rules: Yup.string()
    .max(255, 'Aggregation rules cannot be more than 255 characters'),
  wind_speed_frequency: Yup.string()
    .max(50, 'Data frequency cannot be more than 50 characters'),
  wind_speed_spot_value: Yup.string()
    .max(50, 'Spot value cannot be more than 50 characters'),
  wind_speed_unit: Yup.string()
    .max(50, 'Unit cannot be more than 50 characters'),
  wind_speed_aggregation_rules: Yup.string()
    .max(255, 'Aggregation rules cannot be more than 255 characters'),
  wind_direction_frequency: Yup.string()
    .max(50, 'Data frequency cannot be more than 50 characters'),
  wind_direction_spot_value: Yup.string()
    .max(50, 'Spot value cannot be more than 50 characters'),
  wind_direction_unit: Yup.string()
    .max(50, 'Unit cannot be more than 50 characters'),
  wind_direction_aggregation_rules: Yup.string()
    .max(255, 'Aggregation rules cannot be more than 255 characters'),
});

export default AmbientParametersSchema;
