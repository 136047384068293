export const ambient_parameters = {
  title: 'Ambient Parameters',
  saveLabel: 'Save',
  updateLabel: 'Update',
  questions: [
    {
      title: '6.1 Ambient Temperature',
      forms: [
        {
          type: 'radio',
          description:
            '1. Please specify the location at which Ambient Temperature is measured.',
          name: 'temperature_location',
          other: 'temperature_location_notes',
          fields: [
            { key: 'accInlet', label: 'ACC Inlet' },
            { key: 'gasTurbineInlet', label: 'Gas Turbine Inlet' },
            { key: 'weatherStation', label: 'Weather Station' },
          ],
        },
        {
          type: 'text',
          description:
            '2. Please specify the below parameters for Ambient Temperature.',
          fields: [
            { key: 'temperature_frequency', label: 'Data Frequency' },
            {
              key: 'temperature_spot_value',
              label: 'Spot or Aggregated Value',
            },
            { key: 'temperature_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '3. Please mention the aggregation rules. ',
          name: 'temperature_aggregation_rules',
          label: 'Aggregation rules',
          key: 'temperature_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '6.2 Ambient Pressure',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Ambient Pressure.',
          fields: [
            { key: 'pressure_frequency', label: 'Data Frequency' },
            { key: 'pressure_spot_value', label: 'Spot or Aggregated Value' },
            { key: 'pressure_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'pressure_aggregation_rules',
          label: 'Aggregation rules',
          key: 'pressure_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '6.3 Ambient Humidity',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Ambient Humidity.',
          fields: [
            { key: 'humidity_frequency', label: 'Data Frequency' },
            { key: 'humidity_spot_value', label: 'Spot or Aggregated Value' },
            { key: 'humidity_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'humidity_aggregation_rules',
          label: 'Aggregation rules',
          key: 'humidity_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '6.4 Wind Speed',
      forms: [
        {
          type: 'text',
          description: '1. Please specify the below parameters for Wind Speed.',
          fields: [
            { key: 'wind_speed_frequency', label: 'Data Frequency' },
            { key: 'wind_speed_spot_value', label: 'Spot or Aggregated Value' },
            { key: 'wind_speed_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'wind_speed_aggregation_rules',
          label: 'Aggregation rules',
          key: 'wind_speed_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '6.5 Wind Direction',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Wind Direction.',
          fields: [
            { key: 'wind_direction_frequency', label: 'Data Frequency' },
            {
              key: 'wind_direction_spot_value',
              label: 'Spot or Aggregated Value',
            },
            { key: 'wind_direction_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'wind_direction_aggregation_rules',
          label: 'Aggregation rules',
          key: 'wind_direction_aggregation_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '6.6 Attachments',
      forms: [
        {
          type: 'upload',
          name: 'ambient_parameters_attachments',
          description:
            '1. Please attach any supporting documents for Ambient Parameters.',
          label: 'Upload',
        },
      ],
    },
  ],
};
