import {
  GET_QUESTION_SUCCESS,
  UPLOAD_ASSET_SUCCESS,
  SAVE_QUESTION_SUCCESS,
  DELETE_ASSET_SUCCESS,
} from './questions.types';

const INITIAL_STATE = {
  existing_sensors: {
    historian_type: '',
    historian_type_notes: '',
    server_name: '',
    host_name: '',
    user_name: '',
    password: '',
    confirm_password: '',
    authentication_type: '',
    authentication_type_notes: '',
    limitations: '',
    assets: [],
  },
  new_sensors: {
    monitoring_sensors: '',
    voltage_type: '',
    communication_type: '',
    communication_type_notes: '',
    assets: [],
  },
  acc_general_data: {
    can_tags_map: '',
    turbine_trip: '',
    alarm_levels: '',
    assets: [],
  },
  ambient_parameters: {
    temperature_frequency: '',
    temperature_spot_value: '',
    temperature_location: '',
    temperature_location_notes: '',
    temperature_unit: '',
    temperature_aggregation_rules: '',
    pressure_frequency: '',
    pressure_spot_value: '',
    pressure_unit: '',
    pressure_aggregation_rules: '',
    humidity_frequency: '',
    humidity_spot_value: '',
    humidity_unit: '',
    humidity_aggregation_rules: '',
    wind_speed_frequency: '',
    wind_speed_spot_value: '',
    wind_speed_unit: '',
    wind_speed_aggregation_rules: '',
    wind_direction_frequency: '',
    wind_direction_spot_value: '',
    wind_direction_unit: '',
    wind_direction_aggregation_rules: '',
    assets: [],
  },
  condensate_parameters: {
    manifolds_frequency: '',
    manifolds_spot_value: '',
    manifolds_unit: '',
    manifolds_aggregation_rules: '',
    condensate_tank_frequency: '',
    condensate_tank_spot_value: '',
    condensate_tank_unit: '',
    condensate_tank_aggregation_rules: '',
    extraction_frequency: '',
    extraction_spot_value: '',
    extraction_unit: '',
    extraction_aggregation_rules: '',
    bypass_frequency: '',
    bypass_spot_value: '',
    bypass_unit: '',
    bypass_aggregation_rules: '',
    high_pressure: false,
    intermediate_pressure: false,
    low_pressure: false,
    assets: [],
  },
  fan_parameters: {
    status_frequency: '',
    status_spot_value: '',
    status_unit: '',
    status_aggregation_rules: '',
    current_frequency: '',
    current_spot_value: '',
    current_unit: '',
    current_aggregation_rules: '',
    suction_pressure_frequency: '',
    suction_pressure_spot_value: '',
    suction_pressure_unit: '',
    suction_pressure_aggregation_rules: '',
    suction_temperature_frequency: '',
    suction_temperature_spot_value: '',
    suction_temperature_unit: '',
    suction_temperature_aggregation_rules: '',
    assets: [],
  },
  steam_parameters: {
    flow_frequency: '',
    flow_spot_value: '',
    flow_unit: '',
    flow_notes: '',
    flow_calculation_location: '',
    relevant_flows: '',
    flow_values: '',
    extraction_information: '',
    temperature_frequency: '',
    temperature_spot_value: '',
    temperature_unit: '',
    temperature_notes: '',
    temperature_location: '',
    pressure_frequency: '',
    pressure_spot_value: '',
    pressure_unit: '',
    pressure_notes: '',
    pressure_location: '',
    turbine_output_frequency: '',
    turbine_output_spot_value: '',
    turbine_output_unit: '',
    turbine_notes: '',
    turbine_output_notes: '',
    assets: [],
  },
  acc_components: {
    gearbox_manufacturer: '',
    gear_ratio: '',
    teeth_per_gear: '',
    gearbox_bearing_type: '',
    motor_manufacturer: '',
    motor_power_consumption: '',
    motor_speed: '',
    motor_bearing_type: '',
    motor_type: '',
    fan_manufacturer: '',
    fan_type: '',
    fan_diameter: '',
    nr_of_blades: '',
    coupling_type: '',
    fan_fixation_type: '',
    vacuum_manufacturer: '',
    vacuum_configuration: '',
    valve_status: false,
    motive_steam_pressure: false,
    motive_steam_temperature: false,
    suction_pressure: false,
    suction_temperature: false,
    pump_valve_status: false,
    pump_suction_pressure: false,
    pump_suction_temperature: false,
    pump_cooling_water_temperature: false,
    drain_pump_manufacturer: '',
    drain_pump_status: false,
    drain_pump_volume_flow: false,
    drain_pot_level: false,
    drain_pot_temperature: false,
    drain_pot_volume: '',
    condensate_extraction_pump_manufacturer: '',
    condensate_extraction_pump_status: false,
    condensate_extraction_pump_volume_flow: false,
    condensate_tank_level: false,
    condensate_tank_temperature: false,
    condensate_tank_volume: '',
    cleaning_pump_manufacturer: '',
    cleaning_pump_status: false,
    cleaning_pump_volume_flow: false,
    cleaning_pump_purpose: '',
    fogging_system_status: false,
    fogging_system_volume_flow: false,
    fogging_system_purpose: '',
    bypass_valve_status: '',
    bypass_valve_measurement_type: '',
    wind_mitigation_device_type: '',
    wind_mitigation_device_notes: '',
    anemometer_elevation: '',
    anemometer_location: '',
    location_plant_layout: '',
    wind_direction_references: '',
    assets: [],
  },
  acc_history: {
    cell_by_cell: false,
    street_by_street: false,
    acc_cleaning_notes: '',
    acc_modification: '',
    gearbox: false,
    motor: false,
    fan: false,
    blade_angle: false,
    acc_issues: '',
    assets: [],
  },
};

const questions = (state = INITIAL_STATE, { type, payload }) => {
  const name = window.location.pathname.replace('/', '').replace(/-/g, '_');
  switch (type) {
    case GET_QUESTION_SUCCESS: {
      return {
        ...state,
        [name]: {
          ...state[name],
          ...payload,
          confirm_password: payload.password,
        },
      };
    }
    case SAVE_QUESTION_SUCCESS: {
      return {
        ...state,
        [name]: {
          ...state[name],
          ...payload,
        },
      };
    }
    case UPLOAD_ASSET_SUCCESS: {
      return {
        ...state,
        [name]: {
          ...state[name],
          assets: payload.id
            ? state[name].assets.concat([payload])
            : state[name].assets,
        },
      };
    }
    case DELETE_ASSET_SUCCESS: {
      return {
        ...state,
        [name]: {
          ...state[name],
          assets: state[name].assets.filter(
            (asset) => asset.name !== payload.name
          ),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default questions;
