import React from 'react';

const Introduction = ({ title, description, subtitle, features }) => {
  return (
    <article className='introduction'>
      <h4 dangerouslySetInnerHTML={{ __html: title }} />
      <p
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      <h4>{subtitle}</h4>
      {features.map((feature, index) => (
        <p key={index}>{feature}</p>
      ))}
    </article>
  );
};

export default Introduction;
