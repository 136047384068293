import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { Formik } from 'formik';

import { Grid, Button } from '@material-ui/core';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import {
  GET_QUESTION,
  SAVE_QUESTION,
} from '../../redux/questions/questions.types';
import cms from '../../cms/index';
import schema from '../../schema/index';
import QuestionCard from '../../components/questionnaire/question-card.component';

export const QuestionForm = ({ name }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { components } = useSelector((state) => state.questionnaire);
  const { isSubmitted } = useSelector((state) => state.projectSetup);
  const { id } = components.find((component) => component.name === name);

  const { title, questions, saveLabel, updateLabel } = cms[name];

  const state = useSelector((state) => state.questions);

  const formData = state[name];

  useEffect(() => {
    dispatch({ type: GET_QUESTION, payload: { name, component_id: id } });
  }, [dispatch, name, id]);

  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (saved) {
      history.push('/');
    }
  }, [saved, history]);

  let uploads = 0;

  questions.forEach((question) =>
    question.forms.forEach((form) => form.type === 'upload' && (uploads += 1))
  );

  return (
    <section className='question-section'>
      <header>
        <h2>{title}</h2>
      </header>
      <Formik
        enableReinitialize={true}
        initialValues={formData}
        validationSchema={schema[name]}
        onSubmit={(values) => {
          dispatch({
            type: SAVE_QUESTION,
            payload: { name, component_id: id, values, uploads, setSaved },
          });
        }}
      >
        {({ handleSubmit, ...props }) => (
          <Grid container>
            {questions.map((question, index) => (
              <QuestionCard
                {...question}
                {...props}
                component_id={id}
                key={index}
                componentName={name}
              />
            ))}
            {!isSubmitted && (
              <Grid item sm={12}>
                <Button
                  color='primary'
                  onClick={handleSubmit}
                  variant='contained'
                >
                  {formData.id ? updateLabel : saveLabel}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Formik>
    </section>
  );
};

export default withAuthenticationRequired(QuestionForm);
