export const steam_parameters = {
  title: 'Steam Parameters',
  saveLabel: 'Save',
  updateLabel: 'Update',
  questions: [
    {
      title: '5.1 Steam Flow',
      forms: [
        {
          type: 'text',
          description: '1. Please specify the below parameters for Steam Flow.',
          fields: [
            { key: 'flow_frequency', label: 'Data Frequency' },
            { key: 'flow_spot_value', label: 'Spot or Aggregated Value' },
            { key: 'flow_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'flow_notes',
          label: 'Aggregation rules',
          key: 'flow_notes_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
      ],
    },
    {
      title: '5.2 How is the steam flow calculated in the DCS?',
      forms: [
        {
          type: 'radio',
          description:
            '1. Please specify the location where the Steam Flow is calculated.',
          name: 'flow_calculation_location',
          fields: [
            { key: 'steam_side', label: 'Steam side (before steam turbine)' },
            {
              key: 'condensate_pump_side',
              label: 'Condensate Pump side (after condensate extraction pumps)',
            },
          ],
        },
        {
          type: 'text',
          description: '2. Please specify the below parameters.',
          fields: [
            { key: 'relevant_flows', label: 'Relevent Flows' },
            { key: 'flow_values', label: 'Values' },
            { key: 'extraction_information', label: 'Extraction Information' },
            { key: 'additional_info', label: 'Additional Information' },
          ],
        },
      ],
    },
    {
      title: '5.3 Steam Temperature',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Steam Temperature.',
          fields: [
            { key: 'temperature_frequency', label: 'Data Frequency' },
            {
              key: 'temperature_spot_value',
              label: 'Spot or Aggregated Value',
            },
            { key: 'temperature_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'temperature_notes',
          label: 'Aggregation rules',
          key: 'temperature_notes_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
        {
          type: 'radio',
          description: '3. Please specify the location.',
          name: 'temperature_location',
          other: 'temperature_location_notes',
          fields: [
            {
              key: 'turbineExhaust',
              label: 'Turbine Exhaust',
            },
            {
              key: 'mainDuct',
              label: 'Main Duct',
            },
            {
              key: 'accManifoldInlet',
              label: 'ACC Manifold Inlet',
            },
          ],
        },
      ],
    },
    {
      title: '5.4 Steam Pressure',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Steam Pressure.',
          fields: [
            { key: 'pressure_frequency', label: 'Data Frequency' },
            { key: 'pressure_spot_value', label: 'Spot or Aggregated Value' },
            { key: 'pressure_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'pressure_notes',
          label: 'Aggregation rules',
          key: 'pressure_notes_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
        {
          type: 'radio',
          description: '3. Please specify the location.',
          name: 'pressure_location',
          other: 'pressure_location_notes',
          fields: [
            {
              key: 'turbineExhaust',
              label: 'Turbine Exhaust',
            },
            {
              key: 'mainDuct',
              label: 'Main Duct',
            },
            {
              key: 'accManifoldInlet',
              label: 'ACC Manifold Inlet',
            },
          ],
        },
      ],
    },
    {
      title: '5.5 Steam Turbine Output',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the below parameters for Steam Turbine Output.',
          fields: [
            { key: 'turbine_output_frequency', label: 'Data Frequency' },
            {
              key: 'turbine_output_spot_value',
              label: 'Spot or Aggregated Value',
            },
            { key: 'turbine_output_unit', label: 'Unit' },
          ],
        },
        {
          type: 'textarea',
          description: '2. Please mention the aggregation rules. ',
          name: 'turbine_notes',
          label: 'Aggregation rules',
          key: 'turbine_notes_popover',
          popover:
            'Do you have any specific data recording or aggregation rules?<br/>E.g. Data is recorded only when a change of 5% has been registered from the previous measurement.<br/><br/>Do you apply any minimum or maximum thresholds?<br/>E.g. Data is recorded only when exceeding x units.<br/><br/>Please also specify which value the DCS indicates.',
        },
        {
          type: 'textarea',
          description:
            '3. Please specify related tags, calculations or overall power plant output.',
          name: 'turbine_output_notes',
          label: 'Related Notes',
        },
      ],
    },
    {
      title: '5.6 Attachments',
      forms: [
        {
          type: 'upload',
          name: 'steam_parameters_attachments',
          description:
            '1. Please attach any supporting documents for Steam Parameters.',
          label: 'Upload',
        },
      ],
    },
  ],
};
