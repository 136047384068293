import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { Box, Typography, CircularProgress } from '@material-ui/core';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutline';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

import {
  UPLOAD_ASSET,
  DELETE_ASSET,
  DOWNLOAD_ASSET,
} from '../../redux/questions/questions.types';

const FileUpload = ({ file, name, component_id }) => {
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!file.id) {
      dispatch({
        type: UPLOAD_ASSET,
        payload: { name, file, component_id, setProgress, setShow },
      });
    }
  }, [dispatch, file, component_id, name]);

  return (
    <>
      {show && (
        <Box display='flex' alignItems='center' className='file-upload'>
          {file.id || progress === 100 ? (
            <Box>
              <CloudDownloadOutlinedIcon
                color='primary'
                onClick={() => {
                  dispatch({
                    type: DOWNLOAD_ASSET,
                    payload: { name: file.name },
                  });
                }}
              />
            </Box>
          ) : (
            <Box position='relative' display='inline-flex'>
              <CircularProgress variant='determinate' value={progress} />
              <Box
                top={-6}
                left={2}
                bottom={0}
                right={0}
                position='absolute'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Typography
                  variant='caption'
                  component='div'
                  color='textSecondary'
                >
                  {progress}%
                </Typography>
              </Box>
            </Box>
          )}
          <Box width={280}>
            <Typography variant='body2' color='textSecondary' noWrap={true}>
              {file.name}
            </Typography>
          </Box>
          {(file.id || progress === 100) && (
            <Box className='delete-file'>
              <DeleteOutlinedIcon
                color='disabled'
                onClick={() =>
                  dispatch({
                    type: DELETE_ASSET,
                    payload: {
                      name: file.name,
                      component_id,
                    },
                  })
                }
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default FileUpload;
