import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';

import axios from 'axios';

import { Fetch } from '../fetch';

import {
  GET_QUESTION,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_FAILURE,
  SAVE_QUESTION,
  SAVE_QUESTION_FAILURE,
  SAVE_QUESTION_SUCCESS,
  UPLOAD_ASSET,
  UPLOAD_ASSET_SUCCESS,
  UPLOAD_ASSET_FAILURE,
  DELETE_ASSET,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
  DOWNLOAD_ASSET,
  DOWNLOAD_ASSET_FAILURE,
} from './questions.types';

import { saveAs } from 'file-saver';

function* getQuestion({ payload: { name, component_id } }) {
  try {
    const response = yield Fetch(
      `/onboarding/questions/${name}/${component_id}`
    );
    if (response.ok) {
      const payload = yield response.json();
      const newPayload = {};
      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null) {
          newPayload[key] = value;
        }
      })
      yield put({ type: GET_QUESTION_SUCCESS, payload: newPayload });
    } else {
      yield put({ type: GET_QUESTION_FAILURE });
    }
  } catch (err) {
    yield put({ type: GET_QUESTION_FAILURE });
  }
}

function* saveQuestion({
  payload: { name, component_id, values, uploads, setSaved },
}) {
  try {
    const response = yield Fetch(
      `/onboarding/questions/${name}/${component_id}`,
      {
        body: JSON.stringify({ ...values, uploads }),
        method: 'POST',
      }
    );
    if (response.ok) {
      setSaved(true);
      yield put({ type: SAVE_QUESTION_SUCCESS, payload: values });
    } else {
      yield put({ type: SAVE_QUESTION_FAILURE });
    }
  } catch (err) {
    yield put({ type: SAVE_QUESTION_FAILURE });
  }
}

function* uploadAsset({
  payload: { file, name, component_id, setProgress, setShow },
}) {
  const url = `${localStorage.getItem('acc360.audience')}/onboarding/assets`;
  const { sub, accessToken } = JSON.parse(localStorage.getItem('acc360.user'));
  const formData = new FormData();

  formData.append('file', file);
  formData.append('category', name);
  formData.append('component_id', component_id);

  try {
    const response = yield call(axios.post, url, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'x-spg-sub': sub,
      },
      onUploadProgress: (event) => {
        const progress = Math.round((100 * event.loaded) / event.total);
        setProgress(progress === 100 ? 99 : progress);
      },
    });
    const { id } = yield response.data;
    yield put({
      type: UPLOAD_ASSET_SUCCESS,
      payload: { id, name: file.name, component_id, category: name },
    });
    setShow(false);
  } catch (err) {
    yield put({ type: UPLOAD_ASSET_FAILURE });
  }
}

function* downloadAsset({ payload: { name } }) {
  const path = `/onboarding/assets/${encodeURIComponent(name)}`;
  try {
    const response = yield Fetch(path);
    saveAs(yield response.blob(), name);
  } catch (err) {
    console.error(err);
    yield put({ type: DOWNLOAD_ASSET_FAILURE });
  }
}

function* deleteAsset({ payload: { name, component_id } }) {
  try {
    const response = yield Fetch(
      `/onboarding/assets/${encodeURIComponent(name)}/${component_id}`,
      {
        method: 'DELETE',
      }
    );
    if (response.ok) {
      yield put({ type: DELETE_ASSET_SUCCESS, payload: { name } });
    } else {
      yield put({ type: DELETE_ASSET_FAILURE });
    }
  } catch (err) {
    yield put({ type: DELETE_ASSET_FAILURE });
  }
}

function* getQuestionSaga() {
  yield takeLatest(GET_QUESTION, getQuestion);
}

function* saveQuestionsSaga() {
  yield takeLatest(SAVE_QUESTION, saveQuestion);
}

function* uploadAssetSaga() {
  yield takeEvery(UPLOAD_ASSET, uploadAsset);
}

function* downloadAssetSaga() {
  yield takeLatest(DOWNLOAD_ASSET, downloadAsset);
}

function* deleteAssetSaga() {
  yield takeLatest(DELETE_ASSET, deleteAsset);
}

export {
  getQuestionSaga,
  saveQuestionsSaga,
  uploadAssetSaga,
  deleteAssetSaga,
  downloadAssetSaga,
};
