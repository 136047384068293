import React from 'react';

import { Form } from 'formik';

import { Grid, Divider, Card, CardContent } from '@material-ui/core';

import RadioInput from '../../components/form-elements/radio-input';
import InputGroup from '../../components/form-elements/input-group';
import UploadInput from '../../components/form-elements/upload-input';
import TextArea from '../form-elements/text-area';
import { Checkbox } from '../form-elements/checkbox';
import { Tooltip } from '../tooltip.component';

const getInputComponent = (type) => {
  let Component = RadioInput;
  if (type === 'radio') {
    Component = RadioInput;
  } else if (type === 'text') {
    Component = InputGroup;
  } else if (type === 'upload') {
    Component = UploadInput;
  } else if (type === 'textarea') {
    Component = TextArea;
  } else if (type === 'checkbox') {
    Component = Checkbox;
  }
  return Component;
};

const QuestionCard = ({ title, forms, componentName, ...props }) => {
  return (
    <Grid item className='question-form' sm={12}>
      <Card className='onboarding-form'>
        <CardContent>
          <header>
            <h4>{title}</h4>
          </header>
          <Divider />
          {forms.map(({ type, description, key, popover, ...form }, index) => {
            const Component = getInputComponent(type);
            return (
              <Form key={index}>
                <h5>
                  {description}
                  {popover && <Tooltip {...{ name: key, popover }} />}
                </h5>
                <Component {...props} {...form} componentName={componentName} />
              </Form>
            );
          })}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default QuestionCard;
