import * as Yup from 'yup';

let CondensateParametersSchema = Yup.object().shape({
    manifolds_frequency: Yup.string()
        .max(50, 'Data frequency cannot be more than 50 characters'),
    manifolds_spot_value: Yup.string()
        .max(50, 'Spot value cannot be more than 50 characters'),
    manifolds_unit: Yup.string()
        .max(50, 'Unit cannot be more than 50 characters'),
    manifolds_aggregation_rules: Yup.string()
        .max(255, 'Aggregation rules cannot be more than 255 characters'),
    condensate_tank_frequency: Yup.string()
        .max(50, 'Data frequency cannot be more than 50 characters'),
    condensate_tank_spot_value: Yup.string()
        .max(50, 'Spot value cannot be more than 50 characters'),
    condensate_tank_unit: Yup.string()
        .max(50, 'Unit cannot be more than 50 characters'),
    condensate_tank_aggregation_rules: Yup.string()
        .max(255, 'Aggregation rules cannot be more than 255 characters'),
    extraction_frequency: Yup.string()
        .max(50, 'Data frequency cannot be more than 50 characters'),
    extraction_spot_value: Yup.string()
        .max(50, 'Spot value cannot be more than 50 characters'),
    extraction_unit: Yup.string()
        .max(50, 'Unit cannot be more than 50 characters'),
    extraction_aggregation_rules: Yup.string()
        .max(255, 'Aggregation rules cannot be more than 255 characters'),
    bypass_frequency: Yup.string()
        .max(50, 'Data frequency cannot be more than 50 characters'),
    bypass_spot_value: Yup.string()
        .max(50, 'Spot value cannot be more than 50 characters'),
    bypass_unit: Yup.string()
        .max(50, 'Unit cannot be more than 50 characters'),
    bypass_aggregation_rules: Yup.string()
        .max(255, 'Aggregation rules cannot be more than 255 characters'),
    high_pressure: Yup.boolean(),
    intermediate_pressure: Yup.boolean(),
    low_pressure: Yup.boolean(),
});

export default CondensateParametersSchema;
