import {
  GET_PROJECT_FAILURE,
  GET_PROJECT_SUCCESS,
  SAVE_PROJECT_SUCCESS,
  SUBMIT_PROJECT_SUCCESS,
} from './project-setup.types';

const INITIAL_STATE = {
  isSetupComplete: false,
  isSubmitted: false,
  status: 'READY',
  user: {},
  project: {
    name: '',
    location: '',
    lead_name: '',
    project_contacts: [],
  },
  isLoading: true,
};

const projectSetup = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_PROJECT_SUCCESS: {
      return {
        ...state,
        isSetupComplete: true,
        project: {
          ...state.project,
          ...payload,
        },
        isSubmitted: !!payload.submitted_on,
        isLoading: false,
      };
    }
    case GET_PROJECT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SAVE_PROJECT_SUCCESS: {
      return {
        ...state,
        project: {
          ...state.project,
          ...payload,
        },
        isSetupComplete: true,
        isLoading: false,
      };
    }
    case SUBMIT_PROJECT_SUCCESS: {
      return {
        ...state,
        project: {
          ...state.project,
        },
        isSubmitted: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default projectSetup;
