import { takeLatest, put } from 'redux-saga/effects';

import { Fetch } from '../fetch';

import {
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILURE,
  SAVE_PROJECT,
  SAVE_PROJECT_SUCCESS,
  SAVE_PROJECT_FAILURE,
  SUBMIT_PROJECT,
  SUBMIT_PROJECT_FAILURE,
  SUBMIT_PROJECT_SUCCESS,
} from './project-setup.types';

function* getProject() {
  try {
    const response = yield Fetch('/onboarding/projects');
    if (response.ok) {
      const payload = yield response.json();
      yield put({ type: GET_PROJECT_SUCCESS, payload });
    } else {
      yield put({ type: GET_PROJECT_FAILURE });
    }
  } catch (err) {
    yield put({ type: GET_PROJECT_FAILURE });
  }
}

function* saveProject({ payload }) {
  try {
    const response = yield Fetch(`/onboarding/projects`, {
      body: JSON.stringify(payload),
      method: `${payload.id ? 'PATCH' : 'POST'}`,
    });
    if (response.ok) {
      if (payload.id) {
        yield put({ type: SAVE_PROJECT_SUCCESS, payload });
      } else {
        const { id } = yield response.json();
        yield put({ type: SAVE_PROJECT_SUCCESS, payload: { ...payload, id } });
      }
    } else {
      yield put({ type: SAVE_PROJECT_FAILURE });
    }
  } catch (err) {
    yield put({ type: SAVE_PROJECT_FAILURE });
  }
}

function* submitProject() {
  try {
    const response = yield Fetch(`/onboarding/projects/submit`, {
      method: 'PATCH',
    });
    if (response.ok) {
      yield put({ type: SUBMIT_PROJECT_SUCCESS });
    }
  } catch (err) {
    yield put({ type: SUBMIT_PROJECT_FAILURE });
  }
}

function* getProjectSaga() {
  yield takeLatest(GET_PROJECT, getProject);
}

function* saveProjectSaga() {
  yield takeLatest(SAVE_PROJECT, saveProject);
}

function* submitProjectSaga() {
  yield takeLatest(SUBMIT_PROJECT, submitProject);
}

export { getProjectSaga, saveProjectSaga, submitProjectSaga };
