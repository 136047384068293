import React from 'react';

import { useSelector } from 'react-redux';

import {
  makeStyles,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';

const Progress = () => {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      top: '4px',
    },
    bottom: {
      color: 'rgba(224, 82, 21, 0.27)',
    },
    top: {
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }));

  const classes = useStyles();

  const { progress } = useSelector((state) => state.questionnaire);

  return (
    <Box className={classes.root} display='inline-flex'>
      <CircularProgress
        variant='determinate'
        className={classes.bottom}
        size={56}
        thickness={3}
        value={100}
        color='secondary'
      />
      <CircularProgress
        variant='determinate'
        className={classes.top}
        size={56}
        thickness={3}
        value={progress}
        classes={{
          circle: classes.circle,
        }}
        color='primary'
      />
      <Box
        top={-6}
        left={4}
        bottom={2}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography variant='caption' component='div' color='textSecondary'>
          {progress}%
        </Typography>
      </Box>
    </Box>
  );
};

export default Progress;
