import * as Yup from 'yup';

let SteamParametersSchema = Yup.object().shape({
  flow_frequency: Yup.string().max(
    50,
    'Data frequency cannot be more than 50 characters'
  ),
  flow_spot_value: Yup.string().max(
    50,
    'Spot value cannot be more than 50 characters'
  ),
  flow_unit: Yup.string().max(50, 'Unit cannot be more than 50 characters'),
  flow_notes: Yup.string().max(
    255,
    'Aggregation rules cannot be more than 255 characters'
  ),
  flow_calculation_location: Yup.string(),
  relevant_flows: Yup.string().max(
    50,
    'Data frequency cannot be more than 50 characters'
  ),
  flow_values: Yup.string().max(
    50,
    'Data frequency cannot be more than 50 characters'
  ),
  extraction_information: Yup.string().max(
    50,
    'Data frequency cannot be more than 50 characters'
  ),
  additional_info: Yup.string().max(
    50,
    'Data frequency cannot be more than 255 characters'
  ),
  temperature_frequency: Yup.string().max(
    50,
    'Data frequency cannot be more than 50 characters'
  ),
  temperature_spot_value: Yup.string().max(
    50,
    'Spot value cannot be more than 50 characters'
  ),
  temperature_unit: Yup.string().max(
    50,
    'Unit cannot be more than 50 characters'
  ),
  temperature_notes: Yup.string().max(
    255,
    'Aggregation rules cannot be more than 255 characters'
  ),
  temperature_location: Yup.string(),
  temperature_location_notes: Yup.string()
    .nullable()
    .when('temperature_location', {
      is: 'others',
      then: Yup.string()
        .required('Please specify the Steam Temperature Location')
        .min(2, 'Steam Temperature Location cannot be less than 2 characters')
        .max(
          50,
          'Steam Temperature Location cannot be more than 50 characters'
        ),
    }),
  pressure_frequency: Yup.string().max(
    50,
    'Data frequency cannot be more than 50 characters'
  ),
  pressure_spot_value: Yup.string().max(
    50,
    'Spot value cannot be more than 50 characters'
  ),
  pressure_unit: Yup.string().max(50, 'Unit cannot be more than 50 characters'),
  pressure_notes: Yup.string().max(
    255,
    'Aggregation rules cannot be more than 255 characters'
  ),
  pressure_location: Yup.string(),
  pressure_location_notes: Yup.string()
    .nullable()
    .when('pressure_location', {
      is: 'others',
      then: Yup.string()
        .required('Please specify the Steam Pressure Location')
        .min(2, 'Steam Pressure Location cannot be less than 2 characters')
        .max(50, 'Steam Pressure Location cannot be more than 50 characters'),
    }),
  turbine_output_frequency: Yup.string().max(
    50,
    'Data frequency cannot be more than 50 characters'
  ),
  turbine_output_spot_value: Yup.string().max(
    50,
    'Spot value cannot be more than 50 characters'
  ),
  turbine_output_unit: Yup.string().max(
    50,
    'Unit cannot be more than 50 characters'
  ),
  turbine_notes: Yup.string().max(
    255,
    'Aggregation rules cannot be more than 255 characters'
  ),
  turbine_output_notes: Yup.string().max(
    255,
    'Aggregation rules cannot be more than 255 characters'
  ),
});

export default SteamParametersSchema;
