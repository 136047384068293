import * as Yup from 'yup';

let AccHistorySchema = Yup.object().shape({
    cell_by_cell: Yup.boolean(),
    street_by_street: Yup.boolean(),
    acc_cleaning_notes: Yup.string()
        .max(255, 'ACC cleaning methodology cannot be more than 255 characters'),
    acc_modification: Yup.string()
        .max(255, 'ACC cleaning methodology cannot be more than 255 characters'),
    gearbox: Yup.boolean(),
    motor: Yup.boolean(),
    fan: Yup.boolean(),
    blade_angle: Yup.boolean(),
    acc_issues: Yup.string()
        .max(255, 'ACC issues cannot be more than 255 characters'),
});

export default AccHistorySchema;
