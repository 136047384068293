export const header = {
  title: 'Steam Cycle Advanced Analytics',
  items: [
    { key: 'home', label: 'Home', path: '/' },
    {
      key: 'contact',
      id: 'contact_us',
      label: 'Contact us',
      title: 'Service Desk',
      closeLabel: 'Close',
      description: [
        'SPG Dry Cooling',
        'Avenue Ariane 5',
        '1200 Brussels',
        'Belgium',
        'servicedesk@acc-360.com',
      ],
    },
  ],
  menus: [
    { path: '/project-details', label: 'Project Details' },
    { label: 'Logout ' },
  ],
};
