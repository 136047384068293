import React from 'react';

import { Form } from 'formik';

import { TextField } from '@material-ui/core';

const GeneralDetails = ({
  title,
  label,
  placeholder,
  errors,
  handleChange,
  touched,
  classes,
  values,
}) => {
  return (
    <div>
      <h4>{title}</h4>
      <Form className={classes.root}>
        {Object.entries(label).map(([key, value], index) => (
          <TextField
            required
            value={values[key]}
            error={!!(errors[key] && touched[key])}
            name={key}
            onChange={handleChange}
            id={key}
            key={index}
            label={value}
            placeholder={placeholder[key]}
            helperText={errors[key] && touched[key] ? errors[key] : null}
          />
        ))}
      </Form>
    </div>
  );
};

export default GeneralDetails;
