import * as Yup from 'yup';

let AccComponentsSchema = Yup.object().shape({
  gearbox_manufacturer: Yup.string().max(
    100,
    'Manufacturer name cannot be more than 100 characters'
  ),
  gear_ratio: Yup.number(),
  teeth_per_gear: Yup.number(),
  gearbox_bearing_type: Yup.string().max(
    50,
    'Gearbox bearning type cannot be more than 50 characters'
  ),
  motor_manufacturer: Yup.string().max(
    100,
    'Manufacturer name cannot be more than 100 characters'
  ),
  motor_power_consumption: Yup.number(),
  motor_speed: Yup.number(),
  motor_bearing_type: Yup.string().max(
    50,
    'Motor bearning type cannot be more than 50 characters'
  ),
  motor_type: Yup.string(),
  fan_manufacturer: Yup.string().max(
    100,
    'Manufacturer name cannot be more than 100 characters'
  ),
  fan_type: Yup.string().max(30, 'Fan type cannot be more than 30 characters'),
  fan_diameter: Yup.number(),
  nr_of_blades: Yup.number(),
  coupling_type: Yup.string().max(
    30,
    'Couplying type cannot be more than 30 characters'
  ),
  fan_fixation_type: Yup.string(),
  vacuum_manufacturer: Yup.string().max(
    100,
    'Manufacturer name cannot be more than 100 characters'
  ),
  vacuum_configuration: Yup.string().max(
    100,
    'Manufacturer name cannot be more than 100 characters'
  ),
  valve_status: Yup.boolean(),
  motive_steam_pressure: Yup.boolean(),
  motive_steam_temperature: Yup.boolean(),
  suction_pressure: Yup.boolean(),
  suction_temperature: Yup.boolean(),
  pump_valve_status: Yup.boolean(),
  pump_suction_pressure: Yup.boolean(),
  pump_suction_temperature: Yup.boolean(),
  pump_cooling_water_temperature: Yup.boolean(),
  pump_status: Yup.boolean(),
  drain_pump_manufacturer: Yup.string().max(
    100,
    'Manufacturer name cannot be more than 100 characters'
  ),
  drain_pump_status: Yup.boolean(),
  drain_pump_volume_flow: Yup.boolean(),
  drain_pot_level: Yup.boolean(),
  drain_pot_temperature: Yup.boolean(),
  drain_pot_volume: Yup.number(),
  condensate_extraction_pump_manufacturer: Yup.string().max(
    100,
    'Manufacturer name cannot be more than 100 characters'
  ),
  condensate_extraction_pump_status: Yup.boolean(),
  condensate_extraction_pump_volume_flow: Yup.boolean(),
  condensate_tank_level: Yup.boolean(),
  condensate_tank_temperature: Yup.boolean(),
  condensate_tank_volume: Yup.number(),
  cleaning_pump_manufacturer: Yup.string().max(
    100,
    'Manufacturer name cannot be more than 100 characters'
  ),
  cleaning_pump_status: Yup.boolean(),
  cleaning_pump_volume_flow: Yup.boolean(),
  cleaning_pump_purpose: Yup.string().max(
    255,
    'Cleaning pump purpose cannot be more than 255 characters'
  ),
  fogging_system_status: Yup.boolean(),
  fogging_system_volume_flow: Yup.boolean(),
  fogging_system_purpose: Yup.string().max(
    255,
    'Fogging system purpose cannot be more than 255 characters'
  ),
  bypass_valve_status: Yup.string(),
  bypass_valve_measurement_type: Yup.string(),
  wind_mitigation_installed: Yup.string(),
  wind_mitigation_device_type: Yup.string(),
  wind_mitigation_device_notes: Yup.string().when(
    'wind_mitigation_device_type',
    {
      is: 'others',
      then: Yup.string()
        .required('Please specify the wind mitigation device')
        .max(50, 'Wind mitigation device cannot be more than 50 characters'),
    }
  ),
  anemometer_elevation: Yup.string().max(
    255,
    'Elevation cannot be more than 50 characters'
  ),
  anemometer_location: Yup.string().max(
    255,
    'Location cannot be more than 50 characters'
  ),
  location_plant_layout: Yup.string().max(
    255,
    'Plant Layout Indicators cannot be more than 50 characters'
  ),
  wind_direction_references: Yup.string().max(
    255,
    'Wind direction cannot be more than 255 characters'
  ),
});

export default AccComponentsSchema;
