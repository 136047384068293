export const acc_history = {
  title: 'ACC History',
  saveLabel: 'Save',
  updateLabel: 'Update',
  questions: [
    {
      title: '9.1 Cleaning',
      forms: [
        {
          type: 'text',
          description:
            '1. Please specify the last 5 cleaning ACC cleaning dates.',
          fields: [
            { key: 'cleaning_date_1', label: 'First cleaning' },
            { key: 'cleaning_date_2', label: 'Second cleaning' },
            { key: 'cleaning_date_3', label: 'Third cleaning' },
            { key: 'cleaning_date_4', label: 'Fourth cleaning' },
            { key: 'cleaning_date_5', label: 'Fifth cleaning' },
          ],
        },
        {
          type: 'checkbox',
          description:
            '2. Please specify how cleaning typically performed on the ACC.',
          name: 'acc_cleaning',
          fields: [
            {
              key: 'cell_by_cell',
              label: 'Cell by Cell',
            },
            {
              key: 'street_by_street',
              label: 'Street by Street',
            },
          ],
        },
        {
          type: 'textarea',
          description:
            '3. Please describe the methodology followed for ACC cleaning.',
          name: 'acc_cleaning_notes',
          label: 'Aggregation rules',
        },
      ],
    },
    {
      title: '9.2 Modification and Repair',
      forms: [
        {
          type: 'textarea',
          description:
            '1. Please specify if there any modifications were performed on the ACC after initial installation.',
          name: 'acc_modification',
          label: 'ACC Modification and Repair',
        },
        {
          type: 'checkbox',
          description:
            '2. Please specify if any of the following have been replaced or altered since installation.',
          name: 'acc_alter',
          other: 'acc_alter_notes',
          fields: [
            {
              key: 'gearbox',
              label: 'Gearbox',
            },
            {
              key: 'motor',
              label: 'Motor',
            },
            {
              key: 'fan',
              label: 'Fan',
            },
            {
              key: 'blade_angle',
              label: 'Blade Angle',
            },
          ],
        },
      ],
    },
    {
      title: '9.3 Issues',
      forms: [
        {
          type: 'textarea',
          description:
            '1. Please mention if there have been any major issues in ACC performance or reliability since its installation.',
          name: 'acc_issues',
          label: 'ACC Issues',
        },
      ],
    },
    {
      title: '9.4 Attachments',
      forms: [
        {
          type: 'upload',
          name: 'acc_history',
          description:
            '1. Please attach any supporting documents for ACC History.',
          label: 'Upload',
        },
      ],
    },
  ],
};
