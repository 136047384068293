import * as Yup from 'yup';

const phoneRegExp = /^(\+?\d{1,3}[- ]?)?\d{10}$/;

let ProjectSetupSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a Project name')
    .min(2, 'Project name cannot be less than 2 characters')
    .max(50, 'Project name cannot be more than 50 characters'),
  location: Yup.string()
    .required('Please enter a Location')
    .min(2, 'Project location cannot be less than 2 characters')
    .max(20, 'Project location cannot be more than 50 characters'),
  lead_name: Yup.string()
    .required('Please enter a Project Lead name')
    .min(2, 'Project Lead name cannot be less than 2 characters')
    .max(50, 'Project Lead name cannot be more than 50 characters'),
  project_contacts: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .min(2, 'Name cannot be less than 2 characters')
        .max(50, 'Name cannot be more than 50 characters'),
      email: Yup.string().email('Please provide a valid email address'),
      phone: Yup.string().matches(phoneRegExp, 'Please enter a valid number'),
    })
  ),
});

export default ProjectSetupSchema;
