import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Fab, makeStyles } from '@material-ui/core';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import ComponentCard from '../../components/questionnaire/component-card';
import { content } from '../../content';
import { GET_COMPONENTS } from '../../redux/questionnaire/questionnaire.types';
import Progress from '../../components/progress.component';

export const Questionnaire = () => {
  const dispatch = useDispatch();

  const {
    questionnaire: { cards, title },
    submitLabel,
    frozenLabel,
  } = content;

  const { components, progress } = useSelector((state) => state.questionnaire);
  const { isSubmitted } = useSelector((state) => state.projectSetup);

  useEffect(() => {
    dispatch({ type: GET_COMPONENTS });
  }, [dispatch]);

  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
    },
    submit: {
      verticalAlign: 'bottom',
      marginTop: '10px',
      marginRight: '24px',
      padding: '24px',
      pointerEvents: isSubmitted ? 'none' : 'auto',
      cursor: isSubmitted ? 'not-allowed' : 'pointer',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const onClick = () => {
    history.push('/submission');
  };

  return (
    <section className='onboarding-questionnaire'>
      <header>
        <h2>{title}</h2>
        <div className={classes.root}>
          {progress > 75 && (
            <Fab
              color='primary'
              variant='extended'
              className={classes.submit}
              onClick={onClick}
            >
              {isSubmitted ? frozenLabel : submitLabel}
            </Fab>
          )}
          <Progress />
        </div>
      </header>
      <Grid container spacing={2}>
        {components.map((component, index) => {
          const card = cards.find((card) => card.name === component.name);
          return (
            <ComponentCard
              key={index}
              {...card}
              {...component}
              number={index + 1}
            />
          );
        })}
      </Grid>
    </section>
  );
};

export default withAuthenticationRequired(Questionnaire);
